const ADOBE_TARGET_CONSTANTS = {
    MBOX_NAMES: {
        DEFAULT_MBOX: 'target-global-mbox',
        ORDER_CONFIRMATION_MBOX: 'orderConfirmPage'
    },
    VIEW_TRIGGERS: {
        GLOBAL: {
            RVI_READY: 'rvi_ready'
        },
        HOME_PAGE: {},
        PLP: {
            LOAD_MORE: 'load_more'
        },
        PDP: {
            PRODUCT_COMPARE_READY: 'pdp_compare_ready'
        },
        CART: {
            ITEM_QTY_CHANGE: 'cart_qtychange'
        },
        DELIVERY_OPTIONS: {

        }
    },
    EVENTS: {

    }
}

export { ADOBE_TARGET_CONSTANTS }