import {
  ClickAwayListener,
  Divider,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PDP_CHECK_SHIPPING } from '../../../../../../constants/Tealium';
import { storeLocatorSelector } from '../../../../../../redux/selectors/storeLocator';
import { ReactComponent as ShippingIcon } from '../../../../../../theme/assets/shipping.svg';
import { useGetPageType } from '../../../../../../utils/hooks/get-page-type/GetPageType';
import { ProductUtility } from '../../../../../../utils/product-utility';
import {
  checkTruthy,
  getAttributeValue,
  sendTealiumData,
} from '../../../../../../utils/utils';
import { LineType } from '../../../../../../_foundation/enum/ProductList/ProductList';
import { useSite } from '../../../../../../_foundation/hooks/usesite/useSite';
import { NteDrawer } from '../../../../../Drawers/NteDrawer/NteDrawer';
import { NteButton } from '../../../../../Forms/NteButton/NteButton';
import { PlplayoutConstants } from '../../../../../Layouts/Plp/PlpLayoutConstants';
import { useNteModal } from '../../../../../NteModal/hooks/NteModalHooks';
import { PdpPageConstants } from '../../../../../PDP/PdpPageConstants';
import ESpotModal from '../../../../ESpotModal/ESpotModal';
import { ProductOffer } from '../../../../ProductOffer/ProductOffer';
import { ShippingInfoDrawer } from '../../../../ShippingInfoDrawer/ShippingInfoDrawer';
import { useShippingMessageColorHook } from '../../hooks/ShippingMessageColorHook';
import { ProductCardSectionMessagesConstants } from '../ProductCardSectionMessagesConstants';
import { StoreStock } from './StoreStock/StoreStock';
import { format, isPast } from 'date-fns';
import InfoIcon from '@material-ui/icons/Info';
import { DeliveryOptionConstants } from '../../../../Orders/DeliveryOptions/DeliveryOptions.constants';
import { CartItemCardConstants } from '../../../../ShoppingCart/CartItems/CartItemCard/CartItemCardConstants';
import { useAdobeTarget } from '../../../../../../utils/useAdobeTarget';
import { ADOBE_TARGET_CONSTANTS } from '../../../../../../constants/AdobeTarget';

/**
 * @interface IProductShipping
 */
interface IProductShipping {
  shippingMessage: string;
  sameDayDeliveryMessage?: string;
  storePickupMessage?: JSX.Element | JSX.Element[];
  partNumber: string;
  productName?: string;
  showStoreStockDrawer: any;
  storeStockCloseHandler: any;
  productId: string;
  showShippingInfoDrawer?: any;
  shippingInfoCloseHandler?: any;
  isCompareProduct?: boolean;
  className?: string;
  isPDP?: boolean;
  productCard?: any;
  storePickupLoading?: boolean;
  isProductCompare?: boolean;
  isStockMessage?: boolean;
  lineType: LineType;
}

/**
 * @component ProductShipping renders the Buy section.
 */
const ProductShipping: React.FC<IProductShipping> = ({
  shippingMessage,
  storePickupMessage,
  partNumber,
  productName,
  showStoreStockDrawer,
  storeStockCloseHandler,
  productId,
  className = '',
  shippingInfoCloseHandler,
  isPDP = false,
  productCard,
  sameDayDeliveryMessage,
  storePickupLoading,
  isProductCompare,
  isStockMessage,
  lineType,
}) => {
  const {
    shippingStatusClassName,
    showBackOrderedMessage,
    shippingMessageProps,
  } = useShippingMessageColorHook(shippingMessage);

  const {
    SEE_SHIPPING_OPTIONS,
    ITEM_PAGE,
    PRODUCT_PAGE,
    SEE_DETAILS,
    CART_PAGE_TYPE,
    NOT_AVAILABLE_MESSAGE,
    SHIPPING_INFO_MESSAGE,
    TRUCK_DELIVERY_MESSAGE,
  } = ProductCardSectionMessagesConstants;

  const { DELIVERY_ESPOT } = DeliveryOptionConstants;

  const { GRID, PLP_VIEW } = PlplayoutConstants;

  const { GIFT_CARD_PARTNUMBER } = PdpPageConstants;

  const { t } = useTranslation();

  const { mySite } = useSite();

  const showStoreOnly = ProductUtility.isStoreOnly(productCard);

  const soldAtStores = ProductUtility.isSoldAtStores(productCard);

  const { pageType } = useGetPageType();

  const { LIFTGATESERVICE, YES } = CartItemCardConstants;

  const isPdpPage = pageType === ITEM_PAGE || pageType === PRODUCT_PAGE;

  const isCartPage = pageType === CART_PAGE_TYPE;

  const isGridView = sessionStorage.getItem(PLP_VIEW) === GRID;

  const backOrderedDate = productCard?.backorderDate;

  const { currentStoreDetails } = useSelector(storeLocatorSelector);

  const isLiftgate = getAttributeValue(productCard, LIFTGATESERVICE) == YES;

  const { RenderModal, nteModalClickHandler, showNteModal } = useNteModal({
    id: 'same-day-delivery-details-id',
    ariaLabelledById: 'same-day-delivery-details',
  });

  const [showShippingInfoDrawer, setShowShippingInfoDrawer] = useState(false);

  const { triggerTargetViewWithParameters } = useAdobeTarget();

  const updatePDPShippingReady = useCallback((): void => {

    const shippingDisplayMessage = shippingMessage ?
      shippingMessage :
      showBackOrderedMessage ?
        ProductCardSectionMessagesConstants.BACKORDERED :
        sameDayDeliveryMessage;

    const params = {
      partNumber: partNumber,
      isStockItem: isStockMessage,
      isStoreOnly: showStoreOnly,
      displayedMessage: shippingDisplayMessage,
    };
    triggerTargetViewWithParameters("PDP-shipping-mbox", params, "PDP - ShippingReady");
  }, [isStockMessage, partNumber, sameDayDeliveryMessage, shippingMessage, showBackOrderedMessage, showStoreOnly, triggerTargetViewWithParameters]);

  useEffect(() => {
    if (!isProductCompare && (isPDP || isPdpPage)) {
      updatePDPShippingReady();
    }
  }, [isPDP, isPdpPage, isProductCompare, updatePDPShippingReady]);

  const shippingInfoHandler = (): void => {
    if (!showShippingInfoDrawer) {
      sendTealiumData({
        tealium_event: PDP_CHECK_SHIPPING,
        product_id: partNumber,
        product_name: productName,
      });
      let el = document.getElementById('cxone-guide-container');
      if (el != null) {
        el.className = 'hide-element';
      }
    } else {
      let el = document.getElementById('cxone-guide-container');
      if (el != null) {
        el.className = '';
      }
    }
    setShowShippingInfoDrawer(
      (showShippingInfoDrawer) => !showShippingInfoDrawer
    );
  };

  const {
    RenderModal: RenderLiftGateModal,
    nteModalClickHandler: seeDetailsClickHandler,
    showNteModal: showLiftGateModal,
  } = useNteModal({
    ariaLabelledById: 'see-details-modal',
    id: 'see-details-modal',
    ariaLabel: 'lift-gate-service',
  });

  const wrapWithTealium = (children: JSX.Element) => (
    <>
      {isPdpPage ? (
        <div id='PDP_SHIP_MSG' className='prompt-margin'>
          {children}
          {isLiftgate && (
            <div>
              <Typography variant='body2' className='icon-text info-icon'>
                <span>{TRUCK_DELIVERY_MESSAGE}</span>
                <InfoIcon
                  role='button'
                  fontSize='small'
                  onClick={seeDetailsClickHandler}
                  onKeyDown={seeDetailsClickHandler}
                  type='button'
                  tabIndex={0}
                  aria-hidden={false}
                  style={{ paddingTop: 20 }}
                />
              </Typography>
              {showLiftGateModal && (
                <RenderLiftGateModal
                  showModal={showLiftGateModal}
                  nteModalCloseHandler={seeDetailsClickHandler}>
                  <ESpotModal
                    espotName={DELIVERY_ESPOT}
                    eSpotModalCloseHandler={seeDetailsClickHandler}
                    modalHeaderId='lift-gate-modal'
                  />
                </RenderLiftGateModal>
              )}
            </div>
          )}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <div
      className={`messages-cell product_shipping ${className} ${isGridView && !isCartPage ? 'product-name-grid' : ''
        }`}>
      {!isPDP && (
        <ProductOffer
          productId={
            productId
              ? productId
              : productCard?.uniqueID
                ? productCard.uniqueID
                : productCard.id
          }
        />
      )}

      {partNumber !== GIFT_CARD_PARTNUMBER && (
        <ul className='product-shipping-list'>
          <li className='icon-row shipping'>
            <ShippingIcon />
            <div id='PDP_FREIGHT_INFO' className='label-wrapper'>
              <h3 className='shipping-label'>Shipping:&nbsp;</h3>
              {shippingMessage ? (
                <>
                  {wrapWithTealium(
                    <>
                      {showBackOrderedMessage ? (
                        isPdpPage &&
                          backOrderedDate &&
                          !isPast(new Date(backOrderedDate)) ? (
                          <span className={`${shippingStatusClassName}`}>
                            <span className='color-red'>Backordered: </span>
                            Order now to reserve yours - <br />
                            {`Estimated to ship ${format(
                              new Date(backOrderedDate),
                              'MM/dd/yy'
                            )} `}
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <Tooltip
                                title={SHIPPING_INFO_MESSAGE}
                                placement='right-end'
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={openTooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                onClick={handleTooltipOpen}>
                                <InfoIcon
                                  fontSize='medium'
                                  className='info-icon pdp-shipping-info-icon'
                                />
                              </Tooltip>
                            </ClickAwayListener>
                          </span>
                        ) : (
                          <>{shippingMessageProps?.label}</>
                        )
                      ) : (
                        <>
                          <span className={shippingStatusClassName}>
                            {`${shippingMessage} `}
                          </span>
                        </>
                      )}

                      <br />

                      {(isPdpPage || isCartPage) &&
                        checkTruthy(mySite?.globalBopis) &&
                        sameDayDeliveryMessage &&
                        !showStoreOnly &&
                        currentStoreDetails?.storeName &&
                        isStockMessage && (
                          <span className='color-green'>
                            {`${sameDayDeliveryMessage} `}
                            <button
                              className='inline-text'
                              onClick={nteModalClickHandler}>
                              {t(SEE_DETAILS)}
                            </button>
                          </span>
                        )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {(isPdpPage || isCartPage) &&
                    checkTruthy(mySite?.globalBopis) &&
                    sameDayDeliveryMessage &&
                    !showStoreOnly &&
                    currentStoreDetails?.storeName &&
                    isStockMessage && (
                      <span className='color-green'>
                        {`${sameDayDeliveryMessage} `}
                        <button
                          className='inline-text'
                          onClick={nteModalClickHandler}>
                          {t(SEE_DETAILS)}
                        </button>
                      </span>
                    )}
                </>
              )}

              {isPdpPage && !isProductCompare && !showStoreOnly ? (
                <NteButton
                  onClick={shippingInfoHandler}
                  size='small'
                  className='field-margin'
                  translationKey={t(SEE_SHIPPING_OPTIONS)}
                  variant='outlined'
                  type='button'
                />
              ) : (
                <>
                  {!showStoreOnly ? (
                    <button
                      className='inline-text'
                      onClick={shippingInfoHandler}
                      type='button'>
                      <span> {t(SEE_SHIPPING_OPTIONS)}</span>
                    </button>
                  ) : (
                    NOT_AVAILABLE_MESSAGE
                  )}
                </>
              )}
            </div>
          </li>

          <StoreStock
            partNumber={partNumber}
            productName={productName}
            showStoreStockDrawer={showStoreStockDrawer}
            storeStockCloseHandler={storeStockCloseHandler}
            label={storePickupMessage}
            isPDP={isPDP}
            showStoreOnly={showStoreOnly}
            soldAtStores={soldAtStores}
            storePickupLoading={storePickupLoading}
          />
        </ul>
      )}

      <NteDrawer
        anchor='right'
        id='product-shipping-drawer'
        className='product_shipping-drawer'
        ariaLabelledById='store-locator-header'
        nteDrawerCloseHandler={shippingInfoHandler}
        showNteDrawer={showShippingInfoDrawer}>
        <Divider className='nte_popper-divider' />
        <div className='nte_popper-paper'>
          <ShippingInfoDrawer
            shippingInfoCloseHandler={shippingInfoHandler}
            partNumber={partNumber}
            lineType={lineType}
          />
        </div>
      </NteDrawer>

      {showNteModal && (
        <RenderModal
          showModal={showNteModal}
          nteModalCloseHandler={nteModalClickHandler}>
          <ESpotModal
            eSpotModalCloseHandler={nteModalClickHandler}
            espotName='PDP_SAME_DAY_FAQ'
            modalHeaderId='same-day-delivery-details'
          />
        </RenderModal>
      )}
    </div>
  );
};

export { ProductShipping };
