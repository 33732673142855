import { createReducer } from '@reduxjs/toolkit';
import { SiteInfo } from '../../_foundation/hooks/usesite/SiteInfoService';
import {
  INIT_SITE_FAILURE_ACTION,
  INIT_SITE_SUCCESS_ACTION,
  INIT_SITE_LOADING_ACTION,
  UPDATE_LAST_VISITED_URL_ACTION,
} from '../actions/site';

/**
 * @interface OfflineStatus.
 */
export interface OfflineStatus {
  /**
   * States whether the site is offline or not.
   */
  isSiteOffline: boolean;
  /**
   * States the last visited Url by the user before he went offline.
   */
  lastVisitedUrl: string;
}

/**
 * @interface NteSiteState.
 */
export interface NteSiteState {
  /**
   * Current Site Info to contact backend.
   */
  currentSite?: SiteInfo | null;

  /**
   * Application's Offline status.
   */
  offlineStatus: OfflineStatus;
}

const initialNteSiteState: NteSiteState = {
  currentSite: null,
  offlineStatus: {
    isSiteOffline: false,
    lastVisitedUrl: '/',
  },
};

const siteReducer = createReducer(initialNteSiteState, (builder) => {
  builder.addCase(INIT_SITE_SUCCESS_ACTION, (state: NteSiteState, action) => {
    return {
      currentSite: { ...action.payload },
      offlineStatus: {
        isSiteOffline: false,
        lastVisitedUrl: state.offlineStatus.lastVisitedUrl,
      },
    };
  });

  builder.addCase(INIT_SITE_LOADING_ACTION, (state: NteSiteState, action) => {
    return {
      ...state,
      currentSite: {...action.payload},
    };
  });

  builder.addCase(INIT_SITE_FAILURE_ACTION, (state: NteSiteState, action) => {
    return {
      ...state,
      offlineStatus: {
        isSiteOffline: true,
        lastVisitedUrl: state.offlineStatus.lastVisitedUrl,
      },
    };
  });

  builder.addCase(
    UPDATE_LAST_VISITED_URL_ACTION,
    (state: NteSiteState, action: any) => {
      return {
        ...state,
        offlineStatus: {
          isSiteOffline: state.offlineStatus.isSiteOffline,
          lastVisitedUrl: action.payload.lastVisitedUrl,
        },
      };
    }
  );
});

export { siteReducer };
