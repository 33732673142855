import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { SHOP_ALL, SHOP_STORE } from '../../../constants/routes';
import { RESET_CURRENT_CATEGORY_ID_ACTION } from '../../../redux/actions/categories.actions';
import {
  ADD_CATEGORY_IDS_ACTION,
  CURRENT_BRAND_IDENTIFIER_ACTION,
  CURRENT_BRAND_INFO_ACTION,
  FETCH_BRAND_BY_IDENTIFIER_ACTION,
  FETCH_INVENTORY_BY_IDENTIFIER_ACTION,
  FETCH_PRIVATE_BRANDS_ACTION,
  RESET_PRODUCT_LIST_ACTION,
} from '../../../redux/actions/productList.actions';
import { breadcrumbSelector } from '../../../redux/selectors/breadcrumb.selector';
import { productListSelector } from '../../../redux/selectors/productList.selector';
import { useClearQueryParams } from '../../../utils/hooks/clear-query-params/clear-query-params';
import { useCurrentPathName } from '../../../utils/hooks/current-pathname/CurrentPathName';
import {
  constructMetaTagPageTitle,
  formatQueryParams,
  formatToTitleCase,
  IUtagData,
  parseLinkstoUtagPageData,
} from '../../../utils/utils';
import { UtagPageType } from '../../../_foundation/enum/Tealium/Tealium';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { PageMetaTags } from '../../Helmet/PageMetaTags/PageMetaTags';
import { PlpMetaTags } from '../../Helmet/Plp/PlpMetaTags';
import { PlpLayout } from '../../Layouts/Plp/Plplayout';
import {
  GET_SEO_CONFIG_ACTION,
  REFRESH_SEO_ACTION,
} from '../../Seo/redux/actions/seo';
import { seoSelector } from '../../Seo/redux/selector/seo';
import { useUtagAnalytics } from '../../TealiumUtag/hooks/useUtagAnalytics';
import { FacetConstants } from '../../Widgets/Facets/Facet/FacetConstants';
import { usePreserveQueryParams } from '../../Widgets/Facets/Facet/hooks/PreserveQueryParams';
import { PlpPageConstants } from './PlpPageConstants';
import { useAdobeTarget } from '../../../utils/useAdobeTarget';

/**
 * @component PlpPage renders the plp pages for both categories and brands via seo component.
 */
const PlpPage: React.FC = () => {
  const {
    SEO_SUB_HEAD,
    PLP_ESPOT_1,
    PLP_ESPOT_1A,
    PLP_ESPOT_2,
    BRAND_ATTRIBUTE,
    CONSOLE_WARNING,
    BRAND_DESCRIPTION,
    CATEGORY_DESCRIPTION,
  } = PlpPageConstants;

  const { CATEGORY_QUERY_KEY, FACET_QUERY_KEY, BRAND_QUERY_KEY } =
    FacetConstants;

  const { mySite } = useSite();

  const dispatch = useDispatch();

  const seoConfig = useSelector(seoSelector);

  const history = useHistory();

  usePreserveQueryParams();

  const [facetQueryParam, setFacetQueryParam] = useQueryParam(
    FACET_QUERY_KEY,
    StringParam
  );

  const [categoryQueryParam, setCategoryParams] = useQueryParam(
    CATEGORY_QUERY_KEY,
    StringParam
  );

  const [brandQueryParam, setBrandQueryParam] = useQueryParam(
    BRAND_QUERY_KEY,
    StringParam
  );

  const { t } = useTranslation();

  useClearQueryParams();

  const { currentPathName, pathname } = useCurrentPathName();

  const { privateBrands, totalProducts, facets, currentBrandIdentifier } =
    useSelector(productListSelector);

  const { breadCrumbTrailEntryView, loading: breadcrumbLoading } =
    useSelector(breadcrumbSelector);

  const { pushPageViewForUtag } = useUtagAnalytics();

  const { triggerTargetView } = useAdobeTarget();

  const isShopAll = pathname.includes(SHOP_ALL);

  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const currentPathnameClass = `plp-${currentPathName}`;

  const currentSeoConfig = seoConfig && seoConfig[formattedPath];

  const isBrandPlp = currentSeoConfig && seoConfig[formattedPath].isBrandPlp;

  const topBrandsArray: string[] = useMemo(() => {
    const topBrandsArray: string[] = [];

    const topBrandsValues = facets?.filter(
      (facet) => facet.name.toLowerCase() === BRAND_ATTRIBUTE.toLowerCase()
    )[0]?.entry;

    topBrandsValues?.slice(0, 3).forEach((topBrand) => {
      topBrandsArray.push(topBrand.label);
    });

    return topBrandsArray;
  }, [BRAND_ATTRIBUTE, facets]);

  const pageTitle = constructMetaTagPageTitle(seoConfig, formattedPath);

  const breadcrumbsArray: string[] = useMemo(() => {
    const breadcrumbsArray: string[] = [];

    breadCrumbTrailEntryView.slice(1).forEach((breadcrumbItem) => {
      if (breadcrumbItem.label) {
        breadcrumbsArray.push(breadcrumbItem.label);
      }
    });

    return breadcrumbsArray;
  }, [breadCrumbTrailEntryView]);

  const metaKeyword = useMemo(() => {
    const breadcrumbsKeywords = breadcrumbsArray
      .join(' ')
      .replaceAll(/[^a-zA-Z ]/g, '');

    if (currentSeoConfig && seoConfig[formattedPath].page?.metaKeyword) {
      return seoConfig[formattedPath].page?.metaKeyword;
    } else if (breadcrumbsKeywords) {
      return breadcrumbsKeywords;
    } else {
      if (currentSeoConfig) {
        console.warn(CONSOLE_WARNING);
      }
      return '';
    }
  }, [
    CONSOLE_WARNING,
    breadcrumbsArray,
    currentSeoConfig,
    formattedPath,
    seoConfig,
  ]);

  const breadcrumbArrayLastIndex = breadcrumbsArray.length - 1;

  const brandMetaDescription = useMemo(
    () =>
      `${t(BRAND_DESCRIPTION.TEXT_ONE)} ${totalProducts} ${breadcrumbsArray[breadcrumbArrayLastIndex]
      } ${t(BRAND_DESCRIPTION.TEXT_TWO)}`,
    [
      BRAND_DESCRIPTION,
      breadcrumbArrayLastIndex,
      breadcrumbsArray,
      t,
      totalProducts,
    ]
  );

  const categoryMetaDescription = useMemo(
    () =>
      `${t(CATEGORY_DESCRIPTION.TEXT_ONE)} ${totalProducts} ${breadcrumbsArray[breadcrumbArrayLastIndex]
      } ${t(CATEGORY_DESCRIPTION.TEXT_TWO)} ${breadcrumbsArray[breadcrumbArrayLastIndex]
      } ${t(CATEGORY_DESCRIPTION.TEXT_THREE)} ${topBrandsArray.join(', ')}`,
    [
      CATEGORY_DESCRIPTION,
      breadcrumbArrayLastIndex,
      breadcrumbsArray,
      t,
      topBrandsArray,
      totalProducts,
    ]
  );

  const metaDescription = useMemo(() => {
    const checkBrandPlp =
      isBrandPlp && totalProducts && breadcrumbsArray.length > 0;

    const checkCategoryPlp =
      !isBrandPlp &&
      totalProducts &&
      breadcrumbsArray.length > 0 &&
      topBrandsArray.length > 0;

    if (
      currentSeoConfig &&
      seoConfig[formattedPath].page?.metaDescription &&
      seoConfig[formattedPath].page?.metaDescription.startsWith('override |')
    ) {
      return seoConfig[formattedPath].page?.metaDescription
        .replace('override |', '')
        .trimStart();
    } else if (checkBrandPlp) {
      return brandMetaDescription;
    } else if (checkCategoryPlp) {
      return categoryMetaDescription;
    }
  }, [
    brandMetaDescription,
    breadcrumbsArray,
    categoryMetaDescription,
    currentSeoConfig,
    formattedPath,
    isBrandPlp,
    seoConfig,
    topBrandsArray,
    totalProducts,
  ]);

  /**
   * @method initPrivateBrands Fetches private brands from server only
   * when it's not available in the redux state.
   */
  const initPrivateBrands = useCallback((): void => {
    if (mySite && privateBrands?.length === 0) {
      dispatch(FETCH_PRIVATE_BRANDS_ACTION({ storeID: mySite.storeID }));
    }
  }, [dispatch, mySite, privateBrands?.length]);

  useEffect(() => {
    initPrivateBrands();
  }, [mySite, privateBrands, initPrivateBrands]);

  useEffect(() => {
    return () => {
      dispatch(REFRESH_SEO_ACTION());

      dispatch(RESET_CURRENT_CATEGORY_ID_ACTION());

      dispatch(ADD_CATEGORY_IDS_ACTION([]));

      dispatch(CURRENT_BRAND_IDENTIFIER_ACTION(''));

      dispatch(CURRENT_BRAND_INFO_ACTION({ brandName: '', seo: '' }));

      dispatch(RESET_PRODUCT_LIST_ACTION({ clearAll: true }));
    };
  }, [dispatch]);

  /**
   * @callback initPageUtag Initializes the Utags for the Plp pages.
   */
  const initPageUtag = useCallback((): void => {
    if (!breadcrumbLoading) {
      const isBreadcrumbDataAvailable = breadCrumbTrailEntryView.length !== 0;

      if (
        isBreadcrumbDataAvailable &&
        Boolean(document.getElementById('product-results-feedback')) &&
        isShopAll
      ) {
        const utagPlpPayload: IUtagData = parseLinkstoUtagPageData(
          breadCrumbTrailEntryView,
          UtagPageType.plp
        );

        pushPageViewForUtag(utagPlpPayload);
        triggerTargetView(utagPlpPayload.pageSiteSection);
      }
    }
  }, [breadCrumbTrailEntryView, breadcrumbLoading, isShopAll, pushPageViewForUtag, triggerTargetView]);

  useEffect(() => {
    initPageUtag();
  }, [initPageUtag]);

  const isShopThisStore: boolean =
    history.location.pathname.split('/').splice(0, 2).join('/') === SHOP_STORE;

  /**
   * @method initSeoConfigs Fetches the seo config for the current category.
   */
  const initSeoConfigs = useCallback((): void => {
    if (currentPathName && mySite && isShopAll) {
      dispatch(
        GET_SEO_CONFIG_ACTION({
          identifier: formattedPath,
          seoHref: currentPathName,
          storeID: mySite.storeID,
          currentUrl: pathname,
        })
      );
    }
  }, [currentPathName, mySite, isShopAll, dispatch, formattedPath, pathname]);

  useEffect(() => {
    initSeoConfigs();
  }, [initSeoConfigs]);

  const initSubCategories = useCallback(async (): Promise<void> => {
    if (categoryQueryParam && mySite) {
      const ids: any = formatQueryParams('|').remove(categoryQueryParam);

      if (ids.length !== 0) {
        dispatch(
          FETCH_INVENTORY_BY_IDENTIFIER_ACTION({
            identifier: ids[ids.length - 1],
            storeID: mySite.storeID,
          })
        );
      }
    }
  }, [categoryQueryParam, dispatch, mySite]);

  useEffect(() => {
    initSubCategories();
  }, [initSubCategories]);

  const initBrandPlpId = useCallback((): void => {
    if (
      isBrandPlp &&
      mySite &&
      seoConfig &&
      seoConfig[formattedPath] &&
      !currentBrandIdentifier
    ) {
      dispatch(
        FETCH_BRAND_BY_IDENTIFIER_ACTION({
          identifier: seoConfig[formattedPath].tokenExternalValue,
          storeID: mySite.storeID,
          isBrands: isBrandPlp,
        })
      );
    }
  }, [
    currentBrandIdentifier,
    dispatch,
    formattedPath,
    isBrandPlp,
    mySite,
    seoConfig,
  ]);

  useEffect(() => {
    initBrandPlpId();
  }, [initBrandPlpId]);

  const initCategoryParams = useCallback((): void => {
    if (categoryQueryParam) {
      setCategoryParams(categoryQueryParam);
    }

    if (facetQueryParam) {
      setFacetQueryParam(facetQueryParam);
    }

    if (brandQueryParam) {
      setBrandQueryParam(brandQueryParam);
    }
  }, [
    brandQueryParam,
    categoryQueryParam,
    facetQueryParam,
    setBrandQueryParam,
    setCategoryParams,
    setFacetQueryParam,
  ]);

  useEffect(() => {
    initCategoryParams();
  }, [initCategoryParams]);

  return (
    <div
      className={`page plp_page ${currentPathnameClass} ${isShopThisStore && 'plp-store-page'
        }`}>
      {currentSeoConfig && (
        <PageMetaTags
          pageTitle={pageTitle}
          metaDescription={metaDescription}
          metaKeyword={metaKeyword}
          isPlpPage={true}
        />
      )}

      <PlpLayout
        seoSubHead={SEO_SUB_HEAD}
        plpEspot1={PLP_ESPOT_1}
        plpEspot1A={PLP_ESPOT_1A}
        plpEspot2={PLP_ESPOT_2}
      />

      <PlpMetaTags />
    </div>
  );
};

export default PlpPage;
