import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import { SHOPPING_CART } from '../../../constants/routes';
import { SESSION_INVALID_ACTION } from '../../../redux/actions/network-errors.actions';
import { RESET_CART_ACTION } from '../../../redux/actions/order.actions';
import store from '../../../redux/store';
import { trackUserEvent } from '../../../utils/lucky-orange';
import { PrerenderTimer } from '../../../utils/prerenderTimer';
import { alertMessageId, getLocalStorage } from '../../../utils/utils';
import { CHECKOUT_SESSION_OUT, USER } from '../../constants/cookie';
import { IAuthenticationState } from '../../interface/Authentication/IAuth';

const initializeAxios = (): void => {
  Axios.interceptors.request.use((request: AxiosRequestConfig) => {
    if ((window as { [key: string]: any })['__isPrerender__']) {
      //This is a bot request intercepted by Prerender.io that didn't have the page cached...
      PrerenderTimer.myTimer.setPrerenderTimer();
    }

    return request;
  });

  Axios.interceptors.response.use((response: AxiosResponse) => {
    if ((window as { [key: string]: any })['__isPrerender__']) {
      //This is derived from bot request intercepted by Prerender.io that didn't have the page cached...
      PrerenderTimer.myTimer.setPrerenderTimer();
    }

    return response;
  });

  /** The base url for any request is configured by the setup proxy which redirect calls based on the url*/
};

const makeRequest = async (
  request: AxiosRequestConfig,
  additionalHeaders?: object,
  history?: any,
  isCheckout?: boolean
): Promise<any> => {
  const authenticatedState: IAuthenticationState = getLocalStorage(USER);

  try {
    let authorizedRequest: AxiosRequestConfig = {
      ...request,
    };

    /**
     * If the user is authenticated and the current request doesn't have a header
     * add the authorization tokens to the header.
     */
    if (
      (authenticatedState?.isUserAuthenticated ||
        authenticatedState?.metaData) &&
      !request.headers
    ) {
      const { WCToken, WCTrustedToken, personalizationID } =
        authenticatedState.metaData;

      authorizedRequest = {
        ...authorizedRequest,
        headers: {
          WCToken,
          WCTrustedToken,
          WCPersonalization: personalizationID,
          ...{ ...(additionalHeaders && { ...additionalHeaders }) },
        },
      };
    }

    const response: AxiosResponse = await Axios(authorizedRequest);

    if (response.status === 300) {
      return Promise.resolve({ ...response.data, errorCode: response.status });
    }

    return Promise.resolve({ ...response.data });
  } catch (err) {
    const axiosError = err as AxiosError;
    const isCheckoutFlow =
      history?.location?.pathname?.includes('checkout') ||
      window.location.pathname.includes('checkout');

    if (axiosError.response?.status === 401) {
      trackUserEvent({
        eventMsg: axiosError.response.data,
        eventName: '401 unauthorized error',
      });

      if (isCheckoutFlow || isCheckout) {
        store.dispatch(RESET_CART_ACTION());

        localStorage.setItem(CHECKOUT_SESSION_OUT, 'true');

        history.push(SHOPPING_CART);

        window.location.reload();

        store.dispatch(
          SHOW_NTE_ALERT_ACTION({
            message:
              'You were signed out due to inactivity. Please sign in to see your saved cart.',
            messageId: alertMessageId(),
            severity: 'warning',
          })
        );
      } else {
        store.dispatch(
          SESSION_INVALID_ACTION({
            ...axiosError,
            redirectionUrl: window.location.pathname,
          })
        );
      }

      localStorage.removeItem(USER);

      return;
    }

    return Promise.reject(err);
  }
};

/**
 * @method makeUnauthorizedRequest Makes a plain axios request without authorization
 *
 * @param request
 */
const makeUnauthorizedRequest = async (
  request: AxiosRequestConfig
): Promise<any> => {
  try {
    const response: AxiosResponse = await Axios(request);

    return response.data;
  } catch (e) {
    throw e;
  }
};

export { initializeAxios, makeRequest, makeUnauthorizedRequest };
