import { Divider } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CART_MINI_VIEW_TEALIUM } from '../../constants/Tealium';
import { networkErrorSelector } from '../../redux/selectors/network-errors.selector';
import { orderSelector } from '../../redux/selectors/order.selector';
import { orderDetailsSelector } from '../../redux/selectors/orderDetails.selector';
import { checkTruthy, sendTealiumData } from '../../utils/utils';
import { useSite } from '../../_foundation/hooks/usesite/useSite';
import { NteAlert } from '../NteAlert/NteAlert';
import { MiniCartHeader } from '../Widgets/MiniCart/MiniCartHeader/MiniCartHeader';
import { MiniCartThumbNailList } from '../Widgets/MiniCart/MiniCartThumbnailList/MiniCartThumbnailList';
import { MiniCartConstants } from './MiniCartConstants';
import { IProductListContents } from '../../_foundation/interface/ProductList/IProductList';

interface Props {
  /**
   * @prop miniCartCloseHandler closing the cart drawer.
   */
  miniCartCloseHandler: () => void;

  /**
   * @prop id for mini cart.
   */
  id: string;
}

interface Iproductwithfreeitem {
  /**
   * @prop Iproductwithfreeitem for handling free Items.
   */
  freeorderItem: any;

  freeProductDetails: IProductListContents[];
}

/**
 * @component MiniCart is responsible for rendering
 * the mini cart component inside the Nte drawer.
 */
const MiniCart: React.FC<Props> = ({ miniCartCloseHandler, id }) => {
  const {
    MESSAGE: { ERROR },
    MAX_ITEMS_FLAG,
    MAX_ITEM_QTY,
    MAX_LINE_QTY,
  } = MiniCartConstants;

  const { mySite } = useSite();

  const { t } = useTranslation();

  const { contents } = useSelector(orderDetailsSelector);

  const { errorMessage, errorCode } = useSelector(networkErrorSelector);

  const isCartItemsAvailable = contents.length !== 0;

  const { orderItem, replacementItems, maxLineItems, maxQuantity } =
    useSelector(orderSelector);

  const finalCartArray: Iproductwithfreeitem[] = [];

  const freeItemArray: any = [];
  orderItem.forEach((obj) => {
    if (checkTruthy(obj.freeGift)) {
      let productdetailsTemp = contents.filter(
        (prod) => prod.partNumber === obj.partNumber
      );
      freeItemArray.push(obj.partNumber);
      let cartItemtemp: Iproductwithfreeitem = {
        // Initialize other properties of cartItemtemp here if needed
        freeorderItem: obj,
        freeProductDetails: productdetailsTemp,
      };
      finalCartArray.push(cartItemtemp);
    }
  });
  const partnumbers = contents.map(({ partNumber }) => partNumber);

  const productNames = contents.map(({ name }) => name);

  // const productswithsamepartnumber = orderItem.filter((obj, index, self) => {
  //   // Filter objects whose id appears more than once
  //   return self.findIndex((o) => o.partNumber === obj.partNumber) !== index;
  // });

  orderItem.forEach((obj) => {
    if (!checkTruthy(obj.freeGift)) {
      let productdetailsTemp = contents.filter(
        (prod) => prod.partNumber === obj.partNumber
      );
      freeItemArray.push(obj.partNumber);
      let cartItemtemp: Iproductwithfreeitem = {
        // Initialize other properties of cartItemtemp here if needed
        freeorderItem: obj,
        freeProductDetails: productdetailsTemp,
      };
      finalCartArray.push(cartItemtemp);
    }
  });

  // const productwithfreeitem = orderItem.filter(
  //   (obj: any) => obj.partNumber in freeItms
  // );

  useEffect(() => {
    sendTealiumData({
      tealium_event: CART_MINI_VIEW_TEALIUM,
      product_id: partnumbers,
      product_name: productNames,
    });
  }, [partnumbers, productNames]);

  const maxLineQuantityLimit =
    mySite && mySite.maxQuantity
      ? mySite.maxQuantity
      : process.env.REACT_APP_MAX_QUANTITY;

  const maxLineItemsLimit =
    mySite && mySite.maxLineItems
      ? mySite.maxLineItems
      : process.env.REACT_APP_MAX_LINE_ITEMS;

  const showCartProducts =
    isCartItemsAvailable &&
    !errorMessage &&
    maxLineItems !== MAX_ITEMS_FLAG &&
    maxQuantity !== MAX_ITEMS_FLAG;

  return (
    <>
      <Divider className='nte_popper-divider' />

      <div className='nte_drawer-paper'>
        {/* Cart Header */}
        <MiniCartHeader cartHeaderCloseHandler={miniCartCloseHandler} id={id} />

        {/* Responsible to show the error when max item reached */}
        {errorCode === ERROR.SHOPPING_CART_MAX_ITEMS_ERROR_CODE && (
          <>
            <NteAlert
              messages={[errorMessage]}
              severity='error'
              showClose={false}
            />
          </>
        )}

        {/* Responsible to show warning when maxQuantity reached */}
        {maxQuantity === MAX_ITEMS_FLAG && (
          <>
            <NteAlert
              messages={[t(MAX_ITEM_QTY, { maxLineQuantityLimit })]}
              severity='warning'
              showClose={false}
            />
          </>
        )}

        {/* Responsible to show warning when maxLineItems reached */}
        {maxLineItems === MAX_ITEMS_FLAG && (
          <>
            <NteAlert
              messages={[t(MAX_LINE_QTY, { maxLineItemsLimit })]}
              severity='warning'
              showClose={false}
            />
          </>
        )}

        {/* Cart Body */}
        {showCartProducts && (
          <ul className='block-list'>
            {/* Cart Items */}
            {/* {ProductswithoutfreeItem?.map((cartItem: any, index: number) => (
              <MiniCartThumbNailList
                key={index}
                cartItem={cartItem}
                miniCartCloseHandler={miniCartCloseHandler}
                items={orderItem}
                freeItem={false}
                replacementItems={replacementItems}
              />
            ))} */}
            {finalCartArray?.map((cartItem: any, index: number) =>
              cartItem?.freeProductDetails?.length > 0 ? (
                <MiniCartThumbNailList
                  key={index}
                  cartItem={cartItem.freeProductDetails[0]}
                  miniCartCloseHandler={miniCartCloseHandler}
                  items={orderItem}
                  freeItem={cartItem.freeGift}
                  replacementItems={replacementItems}
                  currentOrderItem={cartItem.freeorderItem}
                />
              ) : null
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export { MiniCart };
