import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { FormApi } from 'final-form';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { MY_STORE_COOKIE } from '../../../../_foundation/constants/cookie';
import { LineType } from '../../../../_foundation/enum/ProductList/ProductList';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import { IFsdData } from '../../../../_foundation/interface/StoreLocator/IStoreLocator';
import {
  CART_DELIVERY_CHECK_STORES_TEALIUM,
  CART_DELIVERY_SHIPPING_CHECK_TEALIUM,
  CART_DELVERY_VIEW_PICKUP_DETAILS_TEALIUM,
} from '../../../../constants/Tealium';
import { SHOPPING_CART } from '../../../../constants/routes';
import { SET_BUTTON_LOADING_ACTION } from '../../../../redux/actions/button.actions';
import { FETCH_EXTENDED_USABLE_SHIPPING_INFO_REQUEST_ACTION } from '../../../../redux/actions/usable-shipping-info';
import { buttonSelector } from '../../../../redux/selectors/button.selector';
import { orderSelector } from '../../../../redux/selectors/order.selector';
import { orderDetailsSelector } from '../../../../redux/selectors/orderDetails.selector';
import { productDetailSelector } from '../../../../redux/selectors/productDetail.selector';
import {
  deliverOptionAndStorePickUpSelector,
  storeLocatorFailSelector,
  storeLocatorSelector,
} from '../../../../redux/selectors/storeLocator';
import { usableShippingInfoSelector } from '../../../../redux/selectors/usable-shipping-info.selector';
import { ReactComponent as CartGoldIcon } from '../../../../theme/assets/cart_gold.svg';
import { ReactComponent as CurbsidePickUpIcon } from '../../../../theme/assets/curbside-pickup.svg';
import { ReactComponent as StorePickUpIcon } from '../../../../theme/assets/delivery-icon-store-pickup.svg';
import { ReactComponent as DeliveryIconStoreBlack } from '../../../../theme/assets/icon-store-black.svg';
import { ReactComponent as ShippingIcon } from '../../../../theme/assets/shipping.svg';
import { useStorePickUpStores } from '../../../../utils/hooks/StoreLocator/search-stores/search-stores_storePickup';
import { checkCartItemsForDateCalculation } from '../../../../utils/shipping-option-utility';
import {
  checkTruthy,
  formatPrice,
  getHighestDateRange,
  getLowestDateRange,
  sendTealiumData,
} from '../../../../utils/utils';
import { NteDrawer } from '../../../Drawers/NteDrawer/NteDrawer';
import { NteButton } from '../../../Forms/NteButton/NteButton';
import { NteForm } from '../../../Forms/React-Form/NteForm/NteForm';
import { NteInputField } from '../../../Forms/React-Form/NteInputField/NteInputField';
import { NteAlert } from '../../../NteAlert/NteAlert';
import { useNteModal } from '../../../NteModal/hooks/NteModalHooks';
import { PdpPageConstants } from '../../../PDP/PdpPageConstants';
import { StoreFinder } from '../../AppBar/SecondaryMenuItems/StoreLocator/MyStore/StoreFinder/StoreFinder';
import { StoreFinderConstants } from '../../AppBar/SecondaryMenuItems/StoreLocator/MyStore/StoreFinder/StoreFinderConstants';
import { useStoreLocator } from '../../AppBar/SecondaryMenuItems/StoreLocator/hooks/StoreLocatorHooks';
import ESpotModal from '../../ESpotModal/ESpotModal';
import { StoreStockConstants } from '../../Plp/ProductCard/ProductCardSectionMessages/ProductShipping/StoreStock/StoreStockConstants';
import { CartItemQuantity } from '../../ShoppingCart/CartItems/CartItemQuantity/CartItemQuantity';
import { DeliveryOptionConstants } from './DeliveryOptions.constants';
import { SeePickUpDetailsModal } from './SeePickUpDetailsModal';
import ShippingMethodOptionsConstants from '../../Checkout/ShippingMethodOptions/ShippingMethodOptions.constants';
import { ShippingUtility } from '../../../../utils/shipping-utility';
import { DeliveryOptionsChildrenSkeleton } from './DeliveryOptionsChildrenSkeleton';
import { ProductCardSectionMessagesConstants } from '../../Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';
import { StoreSeeDetailsModal } from '../../Checkout/StoreCard/StoreSeeDetailsModal';

/**
 * @interface IZipCodeForm
 */

interface IZipCodeForm {
  zipCode?: string;
}

/**
 * @interface IDeliveryOptions
 */
interface IDeliveryOptions {
  deliveryOption: string;
  price: string;
}

interface IDeliveryOptionsChildren {
  isShippingInfoDrawer?: boolean;
  partNumber?: string;
  lineType?: LineType;
}

/**
 * @component DeliveryOptionChildren renders the Children Component of  DeliveryOption Widget.
 */

const DeliveryOptionChildren: React.FC<IDeliveryOptionsChildren> = ({
  isShippingInfoDrawer,
  partNumber,
  lineType,
}) => {
  const {
    TRANSLATIONS,
    DELIVERY_ESPOT,
    VALIDATORS: { ZIP_CODE },
    NULL,
    CONT,
    TAX_ONLY,
    ZERR,
    TBD,
    FREE,
    FACTORY_SHIPPED,
    GET_IT,
    GET_IT_STORE,
    ESPOT_BOPIS,
  } = DeliveryOptionConstants;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { mySite } = useSite();

  const history = useHistory();

  const isCartPage = history.location.pathname === SHOPPING_CART;

  const {
    orderId,
    recordSetCount,
    orderItem,
    x_isCartBopisEligible,
    shippingInfo: orderShippingInfo,
  } = useSelector(orderSelector);

  const { currentStoreDetails, storeDetails, isInventoryFailed } =
    useSelector(storeLocatorSelector);

  const { isStoreLocatorApiThrow500 } = useSelector(storeLocatorFailSelector);

  const { storePickUpDetails, loading } = useSelector(
    deliverOptionAndStorePickUpSelector
  );

  const {
    backOrderedProducts,
    usableShippingMethodData,
    dropShippedOnly,
    restrictedItems,
  } = useSelector(usableShippingInfoSelector);

  const { productDetails } = useSelector(productDetailSelector);

  const {
    ERROR_MESSAGE,
    NO_STORE_INVENTORY_AVAILALE,
    D365_ATTRIBUTE,
    D365_MESSAGE,
    BOPIS_AVAILABILITY,
  } = StoreFinderConstants;

  const { CHECK_STORES_PICKUP } = StoreStockConstants;

  const storeRadius = mySite
    ? mySite.storeLocatorDefaultRadius
    : process.env.REACT_APP_DEFAULT_STORE_LOCATOR_RADIUS;

  const [notAvailableStore, setNotAvailableStore] = useState('');

  const [availableStore, setAvailableStore] = useState('');

  const [currentValue, setCurrentValue] = useState('');

  const myStoreCookie = sessionStorage.getItem(MY_STORE_COOKIE);

  const isD365Store = currentStoreDetails?.Attribute.find(
    (attribute) =>
      attribute.displayName.toLowerCase() === D365_ATTRIBUTE.toLowerCase()
  )?.value;

  const isNotBopis = currentStoreDetails?.Attribute.find(
    (attribute) =>
      attribute.displayName.toLowerCase() === BOPIS_AVAILABILITY.toLowerCase()
  )?.value;

  const showD365AdditionalMessage =
    checkTruthy(isD365Store) &&
    (!checkTruthy(isNotBopis) || !checkTruthy(mySite?.globalBopis));

  const [showStoreStockDrawer, setShowStoreStockDrawer] =
    useState<boolean>(false);

  /**
   * @method storeStockCloseHandler
   * Responsible to show/hide the stock drawer
   */
  const storeStockCloseHandler = (): void => {
    setShowStoreStockDrawer(
      (showStoreStockDrawer: boolean) => !showStoreStockDrawer
    );
  };

  let mystore: string | undefined;

  const [zipCodeForReq, setZipCodeForReq] = useState<string>(
    currentStoreDetails?.postalCode || ''
  );
  if (currentStoreDetails) {
    mystore = currentStoreDetails?.Description[0].displayStoreName;
  } else {
    mystore =
      myStoreCookie &&
      JSON.parse(myStoreCookie)?.Description &&
      JSON.parse(myStoreCookie)?.Description[0]?.displayStoreName;
  }

  /**
   * Responsible to show noStoresFoundForZipcode warning Message
   */
  const noStoresFoundForZipcode =
    storePickUpDetails?.length === 0 &&
    currentValue.length === 5 &&
    !availableStore &&
    !notAvailableStore &&
    !loading;

  /**
   * Responsible to show noStoresFoundForYourLocation Message
   */
  const noStoresFoundForYourLocation =
    storeDetails.length === 0 &&
    !currentStoreDetails &&
    !availableStore &&
    !notAvailableStore;

  useStorePickUpStores({
    orderId,
    isCartPage,
  });

  const zipCodeFormRef = useRef<FormApi<IZipCodeForm>>();

  const { nearByStoreDetails } = useStoreLocator();

  const { loading: progress } = useSelector(buttonSelector);

  const [formInitialValues, setFormInitialValues] = useState<IZipCodeForm>();

  useEffect(() => {
    setFormInitialValues({
      zipCode: isShippingInfoDrawer
        ? currentStoreDetails?.postalCode.trim()
        : '',
    });
  }, [nearByStoreDetails, currentStoreDetails, isShippingInfoDrawer]);

  /**
   * @method storePickupMessageAnchorStore
   * Responsible to set the available/nonAvailable message when store is selected
   */
  const storePickupMessageAnchorStore = useCallback((): void => {
    if (myStoreCookie && !loading && !isCartPage) {
      const anchorStoreStorePickUp =
        storePickUpDetails &&
        storePickUpDetails.filter(
          (inventory) => inventory.Description[0]?.displayStoreName === mystore
        );

      /**
       * if orderInvetory for a order is 0
       */
      if (
        anchorStoreStorePickUp &&
        anchorStoreStorePickUp[0]?.orderInventory.length === 0
      ) {
        setNotAvailableStore(
          anchorStoreStorePickUp[0]?.Description[0]?.displayStoreName
        );
        setAvailableStore('');
        return;
      }

      const storePickUpInventoryDetails = storePickUpDetails.filter(
        (inventory) =>
          inventory.Description[0]?.displayStoreName === mystore &&
          inventory.orderInventory.length !== Number(recordSetCount)
      );

      setNotAvailableStore(
        storePickUpInventoryDetails[0]?.Description[0]?.displayStoreName
      );
      setAvailableStore('');
      if (storePickUpInventoryDetails.length === 0) {
        const storePickUpInventoryDetails = storePickUpDetails.filter(
          (inventory) =>
            inventory.Description[0]?.displayStoreName === mystore &&
            inventory.orderInventory.length === Number(recordSetCount)
        );
        setAvailableStore(
          storePickUpInventoryDetails[0]?.Description[0]?.displayStoreName
        );
        setNotAvailableStore('');
      }
    }

    if (myStoreCookie && orderShippingInfo && isCartPage) {
      /**
       * if orderInvetory for a order is 0
       */
      if (
        orderShippingInfo.some((item) => parseInt(item.storeInventory) < 0) ||
        !checkTruthy(x_isCartBopisEligible)
      ) {
        setNotAvailableStore(mystore ?? '');
        setAvailableStore('');
        return;
      }

      let inventoryRequirementAtStore = new Map<string, number>();
      let actualInventoryAtStore = new Map<string, number>();

      orderItem.forEach((item) => {
        inventoryRequirementAtStore.has(item.partNumber)
          ? inventoryRequirementAtStore.set(
              item.partNumber,
              Number(inventoryRequirementAtStore.get(item.partNumber)) +
                Number(item.quantity)
            )
          : inventoryRequirementAtStore.set(
              item.partNumber,
              Number(item.quantity)
            );
      });
      //orderShippingInfo will have quantity per partnumber. Using map to avoid duplicate partnumbers in the response.
      orderShippingInfo.forEach((item) => {
        actualInventoryAtStore.set(
          item.partNumber,
          Number(item.storeInventory)
        );
      });

      for (let partNumber of inventoryRequirementAtStore.keys()) {
        if (
          (inventoryRequirementAtStore.get(partNumber) ?? 0) >
          (actualInventoryAtStore.get(partNumber) ?? 0)
        ) {
          setNotAvailableStore(mystore ?? '');
          setAvailableStore('');
          return;
        }
      }

      setAvailableStore(mystore ?? '');
      setNotAvailableStore('');
    }
  }, [
    orderShippingInfo,
    myStoreCookie,
    mystore,
    orderItem,
    isCartPage,
    loading,
    recordSetCount,
    storePickUpDetails,
    x_isCartBopisEligible,
  ]);

  const { contents } = useSelector(orderDetailsSelector);

  const productNames = contents.map(({ name }) => name);

  const partNumbers: string[] = [];

  contents.forEach(({ partNumber }) => {
    partNumbers.push(partNumber);
  });

  const deliveryOptionsCheckHandler = (): void => {
    sendTealiumData({
      tealium_event: CART_DELIVERY_SHIPPING_CHECK_TEALIUM,
      product_id: partNumbers,
      product_name: productNames,
      cart_shipping_zipcode:
        myStoreCookie && JSON.parse(myStoreCookie).postalCode
          ? JSON.parse(myStoreCookie).postalCode
          : undefined,
    });
  };

  const checkStoreStockHandler = (): void => {
    sendTealiumData({
      tealium_event: CART_DELIVERY_CHECK_STORES_TEALIUM,
      product_id: partNumbers,
      product_name: productNames,
      cart_shipping_zipcode:
        myStoreCookie && JSON.parse(myStoreCookie).postalCode
          ? JSON.parse(myStoreCookie).postalCode
          : undefined,
    });
  };

  const seePickupDetailsHandler = (): void => {
    sendTealiumData({
      tealium_event: CART_DELVERY_VIEW_PICKUP_DETAILS_TEALIUM,
      product_id: partNumbers,
      product_name: productNames,
      cart_shipping_zipcode:
        myStoreCookie && JSON.parse(myStoreCookie).postalCode
          ? JSON.parse(myStoreCookie).postalCode
          : undefined,
    });
  };

  /**
   * @hook
   * Responsible to call storePickupMessageAnchorStore
   */
  useEffect(() => {
    if (myStoreCookie && JSON.parse(myStoreCookie)?.storeName) {
      storePickupMessageAnchorStore();
    }
  }, [
    loading,
    myStoreCookie,
    storePickupMessageAnchorStore,
    isCartPage,
    orderShippingInfo,
    orderItem,
  ]);

  const orderLookUpFormValidation = (values: IZipCodeForm) => {
    return {};
  };

  const { DEFAULT_QTY } = PdpPageConstants;

  const [selectedQuantity, setselectedQuantity] = useState(DEFAULT_QTY);

  /**
   * @method handleSubmit
   * Calls the extended usable shipping info API when the
   * "check" button is clicked in the shipping info drawer
   */
  const handleSubmit = ({ zipCode }: IZipCodeForm) => {
    setCurrentValue('');
    try {
      if (zipCode) {
        dispatch(SET_BUTTON_LOADING_ACTION({ loading: true }));

        let requestData = {};
        if (isCartPage) {
          requestData = {
            orderID: orderId,
          };
        } else {
          requestData = {
            partNumber: partNumber ? partNumber : productDetails.partNumber,
            quantity: selectedQuantity,
            lineType: lineType,
          };
        }

        dispatch(
          FETCH_EXTENDED_USABLE_SHIPPING_INFO_REQUEST_ACTION({
            storeID: mySite?.storeID,
            zipCode,
            physicalStoreId: currentStoreDetails?.uniqueID,
            ...requestData,
          })
        );
        !progress && setCurrentValue(zipCode);
      }
    } catch (err) {
      setCurrentValue('');
      throw err;
    }
  };

  /**
   * @method getAnchorShippingInfo
   * Gets the shipping info for the current anchor store
   */
  /* eslint-disable react-hooks/exhaustive-deps */
  const getAnchorShippingInfo = useCallback(() => {
    if (
      isShippingInfoDrawer &&
      currentStoreDetails &&
      mySite &&
      productDetails
    ) {
      dispatch(
        FETCH_EXTENDED_USABLE_SHIPPING_INFO_REQUEST_ACTION({
          storeID: mySite?.storeID,
          zipCode: zipCodeForReq,
          physicalStoreId: currentStoreDetails?.uniqueID,
          partNumber: partNumber,
          quantity: selectedQuantity,
          lineType: lineType,
        })
      );

      dispatch(SET_BUTTON_LOADING_ACTION({ loading: true }));
    }
  }, [
    isShippingInfoDrawer,
    currentStoreDetails,
    mySite,
    productDetails,
    dispatch,
    partNumber,
    selectedQuantity,
    lineType,
  ]);

  const quantityCall = useCallback((zipCode) => {
    zipCodeFormRef.current?.submit();
    // avoid call
  }, []);

  useEffect(() => {
    getAnchorShippingInfo();
  }, [getAnchorShippingInfo]);

  const createRow = (deliveryOption: any, price: string) => {
    return { deliveryOption, price };
  };

  const deliveryOptionsRows: IDeliveryOptions[] = [];

  const ligtGateEligibleItem: string[] = [];

  const hasTruckShipMode = usableShippingMethodData.some(
    (shipMode) =>
      shipMode.carrier?.toLowerCase() ===
      ProductCardSectionMessagesConstants.TRUCK_SHIP_METHOD.toLowerCase()
  );

  usableShippingMethodData.map((mode) => {
    const nullDataCheck =
      mode.lineItems &&
      mode.lineItems.toLowerCase() !== NULL &&
      mode.lineItems.toLowerCase() !== '[]';
    const lineItemData: IFsdData[] = nullDataCheck
      ? JSON.parse(mode.lineItems)
      : [];

    if (Number(mode?.totalLiftgate) > 0) {
      ligtGateEligibleItem.push(mode?.shipModeCode);
    }

    let deliveryEstimate;
    if (lineItemData.length > 0) {
      const lineItems = checkCartItemsForDateCalculation(
        lineItemData,
        backOrderedProducts,
        orderItem?.length,
        hasTruckShipMode
      );
      if (hasTruckShipMode) {
        deliveryEstimate = getHighestDateRange(lineItems);
      } else {
        deliveryEstimate = getLowestDateRange(lineItems);
      }
    } else {
      if (nullDataCheck) {
        const lineItems = checkCartItemsForDateCalculation(
          JSON.parse(mode.lineItems),
          backOrderedProducts,
          orderItem?.length,
          hasTruckShipMode
        );

        if (hasTruckShipMode) {
          deliveryEstimate = getHighestDateRange(lineItems);
        } else {
          deliveryEstimate = getLowestDateRange(lineItems);
        }
      }
    }

    const { SDD_METHOD } = ShippingMethodOptionsConstants;
    const includeCarrier =
      mode.carrier === SDD_METHOD || mode.carrier === 'Ship to Store';

    const { estimatedDelivery, resultCarrier } =
      ShippingUtility.formatIOMStoreId(
        mode.iomStoreId,
        mode.carrier,
        deliveryEstimate,
        includeCarrier
      );

    deliveryOptionsRows.push(
      createRow(
        !isShippingInfoDrawer && estimatedDelivery ? (
          <>
            <Typography variant='h5'>{estimatedDelivery}</Typography>
            <Typography variant='body2'>
              {Number(mode?.totalLiftgate) > 0 &&
              mode?.carrier !== 'Ship to Store'
                ? `${resultCarrier} *`
                : resultCarrier}
              {dropShippedOnly && (
                <>
                  <br /> {t(FACTORY_SHIPPED)}
                </>
              )}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant='h5'>{estimatedDelivery}</Typography>
            <Typography variant='body2'>
              {Number(mode?.totalLiftgate) > 0 &&
              mode?.carrier !== 'Ship to Store'
                ? `${resultCarrier} *`
                : resultCarrier}
            </Typography>
            {(lineType === LineType.D || lineType === LineType.D1) && (
              <Typography variant='body2'>{t(FACTORY_SHIPPED)}</Typography>
            )}
          </>
        ),
        `${
          mode.shipModeCode !== (CONT || TAX_ONLY || ZERR)
            ? formatPrice(mode.totalCost.toString())
            : TBD
        }`
      )
    );

    return mode;
  });

  const { RenderModal, nteModalClickHandler, showNteModal } = useNteModal({
    ariaLabelledById: 'delivery-option-card',
    id: 'delivery-option-card-modal',
  });

  const seePickUpIcons = [
    {
      icon: CartGoldIcon,
      info: t(TRANSLATIONS.SEE_PICK_UP_DETAILS.INFO1),
    },
    {
      icon: DeliveryIconStoreBlack,
      info: t(TRANSLATIONS.SEE_PICK_UP_DETAILS.INFO2),
    },
    {
      icon: ShippingIcon,
      info: t(TRANSLATIONS.SEE_PICK_UP_DETAILS.INFO3),
    },
    {
      icon: CurbsidePickUpIcon,
      info: t(TRANSLATIONS.SEE_PICK_UP_DETAILS.INFO4),
    },
  ];

  const seePickUpDetails = [
    t(TRANSLATIONS.SEE_PICK_UP_DETAILS.LINE_ONE),
    t(TRANSLATIONS.SEE_PICK_UP_DETAILS.LINE_TWO),
    t(TRANSLATIONS.SEE_PICK_UP_DETAILS.LINE_THREE),
    t(TRANSLATIONS.SEE_PICK_UP_DETAILS.LINE_FOUR),
    t(TRANSLATIONS.SEE_PICK_UP_DETAILS.LINE_FIVE),
    t(TRANSLATIONS.SEE_PICK_UP_DETAILS.LINE_SIX),
  ];

  const {
    RenderModal: RenderLiftGateModal,
    nteModalClickHandler: seeDetailsClickHandler,
    showNteModal: showLiftGateModal,
  } = useNteModal({
    ariaLabelledById: 'see-details',
    id: 'see-details-modal',
    ariaLabel: 'lift-gate-service',
  });

  const {
    RenderModal: RenderSeeStoreDetails,
    nteModalClickHandler: seeStoreModalClickHandler,
    showNteModal: showSeeStoreDetailsModal,
  } = useNteModal({
    ariaLabelledById: 'see-store-details',
    id: 'see-store-details-modal',
  });

  return (
    <div className='delivery_options_children'>
      <RenderModal
        ariaLabelledById='delivery-option'
        id='delete-address-modal'
        nteModalCloseHandler={nteModalClickHandler}
        showModal={showNteModal}>
        <SeePickUpDetailsModal
          modalCloseHandler={nteModalClickHandler}
          modalHeaderId='delivery-option-card'
          modalHeaderText={t(TRANSLATIONS.STORE_PICK_TITLE)}
          seePickUpIcons={seePickUpIcons}
          seePickUpDetails={seePickUpDetails}
        />
      </RenderModal>

      <NteForm
        name='order-lookup-form'
        className='order-lookup-form'
        getFormReference={(formReference) => {
          zipCodeFormRef.current = formReference;
        }}
        onSubmit={handleSubmit}
        initialValues={formInitialValues}
        validate={orderLookUpFormValidation}>
        <Typography variant='body2' className='color-brown prompt-margin'>
          {t(TRANSLATIONS.DELIVERY_OPTION_SUBTITLE_PART1)}

          <b>{t(TRANSLATIONS.DELIVERY_OPTION_SUBTITLE_PART2)}</b>

          {t(TRANSLATIONS.DELIVERY_OPTION_SUBTITLE_PART3)}

          <b>{t(TRANSLATIONS.DELIVERY_OPTION_SUBTITLE_PART4)}</b>
        </Typography>

        <Typography variant='h3' className='icon-text prompt-margin'>
          <ShippingIcon />
          {t(TRANSLATIONS.DELIVERY_OPTION_SHIPPING_TITLE)}
        </Typography>

        {isShippingInfoDrawer && (
          <CartItemQuantity
            id={productDetails.partNumber}
            isShippingInfoDrawer={true}
            isFromPDP={true}
            className='field-margin'
            onSelectQuantityChange={setselectedQuantity}
            itemQuantity={selectedQuantity}
            quantityChangeEvent={quantityCall}
            index={0}
          />
        )}

        <div className='field-row-2 field-margin'>
          <NteInputField
            {...ZIP_CODE}
            onChange={(e) => {
              setZipCodeForReq(e.target.value);
            }}
            disabled={progress}
          />

          <NteButton
            translationKey={t(
              TRANSLATIONS.TRANSLATION_KEY.DELIVERY_OPTION_BUTTON_KEY
            )}
            variant='outlined'
            onClick={() => {
              deliveryOptionsCheckHandler();
            }}
            type='submit'
            loading={progress}
          />
        </div>

        {deliveryOptionsRows?.length > 0 && !progress ? (
          <>
            <Typography variant='h4'>{GET_IT}</Typography>
            <TableContainer
              component='div'
              className='shipping-options-table prompt-margin nte_table'>
              <Table>
                <TableBody role='rowgroup'>
                  {deliveryOptionsRows?.map(
                    (row: IDeliveryOptions, index: number) => {
                      return (
                        <TableRow key={index} role='row'>
                          <TableCell>{row.deliveryOption}</TableCell>
                          <TableCell>
                            {row.price === TBD
                              ? ''
                              : row.price === '$0.00'
                              ? FREE
                              : row.price}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : progress ? (
          <DeliveryOptionsChildrenSkeleton />
        ) : (
          <>
            {restrictedItems.length > 0 && (
              <Typography className='color-red field-margin'>
                {isCartPage
                  ? t(TRANSLATIONS.RESTRICTED_ITEM_MESSAGE_CART, {
                      restrictedItems,
                    })
                  : t(TRANSLATIONS.RESTRICTED_ITEM_MESSAGE_PDP_PLP, {})}
              </Typography>
            )}
          </>
        )}

        {ligtGateEligibleItem?.length > 0 && (
          <>
            {showLiftGateModal && (
              <RenderLiftGateModal
                showModal={showLiftGateModal}
                nteModalCloseHandler={seeDetailsClickHandler}>
                <ESpotModal
                  espotName={DELIVERY_ESPOT}
                  eSpotModalCloseHandler={seeDetailsClickHandler}
                  modalHeaderId='lift-gate-modal'
                />
              </RenderLiftGateModal>
            )}
          </>
        )}

        {!isShippingInfoDrawer && (
          <>
            <Typography variant='h3' className='icon-text prompt-margin'>
              <StorePickUpIcon
                className='icon-small'
                role={'img'}
                aria-label='store pickup icon'
              />
              {t(TRANSLATIONS.STORE_PICKUP)}
            </Typography>

            {(isInventoryFailed || isStoreLocatorApiThrow500) && (
              <NteAlert
                severity='error'
                messages={[`${NO_STORE_INVENTORY_AVAILALE}`]}
                showClose={false}
              />
            )}

            {notAvailableStore && (
              <Typography className='color-gray-89 field-margin'>
                {t(TRANSLATIONS.NOT_AVAILABLE_MESSAGE, { notAvailableStore })}
              </Typography>
            )}

            {availableStore && !showD365AdditionalMessage && (
              <div className='delivery_options_children'>
                <Typography variant='h4'>{GET_IT_STORE}</Typography>
                <TableContainer
                  component='div'
                  className='shipping-options-table prompt-margin nte_table'>
                  <Table>
                    <TableBody role='rowgroup'>
                      <TableRow role='row'>
                        <TableCell>
                          <>
                            <RenderSeeStoreDetails
                              ariaLabelledById='see-store-details'
                              id='see-store-details-cart'
                              nteModalCloseHandler={seeStoreModalClickHandler}
                              showModal={showSeeStoreDetailsModal}>
                              <StoreSeeDetailsModal
                                modalCloseHandler={seeStoreModalClickHandler}
                                modalHeaderId='see-store-details-id'
                                modalHeaderText={availableStore}
                                storeDetailsData={currentStoreDetails}
                                espotData={ESPOT_BOPIS}
                              />
                            </RenderSeeStoreDetails>
                            <Typography variant='h5'>
                              {t(TRANSLATIONS.BOPIS_AVAILABLE_MESSAGE)}
                            </Typography>

                            <button
                              onClick={() => {
                                seeStoreModalClickHandler();
                              }}
                              className='inline-text link-effect'
                              role='link'>
                              {t(TRANSLATIONS.AVAILABLE_MESSAGE_STORE, {
                                availableStore,
                              })}
                            </button>
                          </>
                        </TableCell>
                        <TableCell>
                          <Typography variant='body2'>{FREE}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}

            {showD365AdditionalMessage && !notAvailableStore && (
              <Typography className='color-gray-89 field-margin'>
                {D365_MESSAGE}
              </Typography>
            )}

            {noStoresFoundForYourLocation &&
              !noStoresFoundForZipcode &&
              !isStoreLocatorApiThrow500 && (
                <NteAlert
                  severity='warning'
                  messages={[
                    `${t(ERROR_MESSAGE.NO_NORTHERN_TOOL_STORES_LOCATION, {
                      storeRadius,
                    })}`,
                  ]}
                  showClose={false}
                />
              )}

            {noStoresFoundForZipcode && !isStoreLocatorApiThrow500 && (
              <NteAlert
                severity='warning'
                messages={[
                  `${t(ERROR_MESSAGE.NO_NORTHERN_TOOL_STORES_ZIPCODE, {
                    storeRadius,
                    currentValue,
                  })}`,
                ]}
                showClose={false}
              />
            )}
          </>
        )}
      </NteForm>

      {!isShippingInfoDrawer && (
        <>
          <NteButton
            onClick={() => {
              storeStockCloseHandler();
              checkStoreStockHandler();
            }}
            fullWidth={true}
            size='small'
            className='inline-text field-margin'
            translationKey={t(TRANSLATIONS.TRANSLATION_KEY.CHECK_STORE_STOCK)}
            type='button'
          />
          {notAvailableStore && (
            <NteButton
              onClick={() => {
                nteModalClickHandler();
                seePickupDetailsHandler();
              }}
              fullWidth={true}
              size='small'
              className='inline-text'
              translationKey={t(
                TRANSLATIONS.TRANSLATION_KEY.SEE_PICK_UP_DETAILS
              )}
              type='button'
            />
          )}

          <NteDrawer
            anchor='right'
            id='store-locator-drawer'
            className='delivery-options-children'
            ariaLabelledById='store-locator-header'
            nteDrawerCloseHandler={storeStockCloseHandler}
            showNteDrawer={showStoreStockDrawer}>
            <Divider className='nte_popper-divider' />
            <div className='nte_popper-paper'>
              <StoreFinder
                menuTitle={t(CHECK_STORES_PICKUP)}
                storeFinderCloseHandler={storeStockCloseHandler}
                orderId={orderId}
              />
            </div>
          </NteDrawer>
        </>
      )}
    </div>
  );
};

export { DeliveryOptionChildren };
