import { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { reregister, unregister } from '../../../serviceWorkerRegistration';
import { makeRequest } from '../../../_foundation/apis/axios/axiosConfig';
import { RefreshConstants } from './RefreshConstants';
import { getLocalStorage, setLocalStorage } from '../../utils';

/**
 * @method useRefresh is responsible for refreshing the page while the
 * user navigates when the app's version has been updated.
 */
const useRefresh = () => {
  const { VERSION_INFO_URL } = RefreshConstants;

  const history = useHistory();

  const [isVersionChanged, setIsVersionChanged] = useState<boolean>(false);

  /**
   * @callback refresh refreshes the newly navigated page
   * to ensure the app is on the updated version.
   */
  const refresh = useCallback(() => {
    if (isVersionChanged) {
      window.location.reload();
    }
  }, [isVersionChanged]);

  useEffect(() => {
    refresh();
  }, [isVersionChanged, refresh]);

  /**
   * @callback initVersionChange responsible for accessing the version_info
   * and triggering the app refresh when the version mismatches.
   */
  const initVersionChange = useCallback(async (): Promise<void> => {
    try {
      const versionInfoRequest: AxiosRequestConfig = {
        url: VERSION_INFO_URL,
      };

      const versionInfoResponse = await makeRequest(versionInfoRequest);

      if (versionInfoResponse) {
        const currentVersion = getLocalStorage("app_version");

        /**
         * NOTE: App version and the currentVersion from the BE has been compared directly as string
         * values instead of converting them into number, since version numbers have 2 decimal points.
         *
         * javascript can convert a decimal number like "0.27" as Number("0.27") --> 0.27
         *
         * But javascript cannot convert 2 decimal point numbers like "0.27.33" since converting can throw
         * Nan. For Example: Number("0.27.33") --> NaN
         *
         * Therefore we can directly compare the equality of 2 string values and this implementation works fine.
         */
        const versionChanged = versionInfoResponse.version !== currentVersion && window.location.pathname !== '/search';
        setIsVersionChanged(versionChanged);
        if (versionChanged) {
          setLocalStorage("app_version", versionInfoResponse.version)
          await unregister();
          await reregister();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [VERSION_INFO_URL]);

  useEffect(() => {
    initVersionChange();
  }, [initVersionChange, history.location.pathname]);
};

export { useRefresh };
