import { call, put } from 'redux-saga/effects';
import { StorePickupService } from '../../../_foundation/apis/storePickup/storePickup.service';
import { CheckoutFlows } from '../../../components/Checkout/enum/Checkout';
import {
  DELIVERY_PAGE_API_LOADING,
  FETCH_CHECKOUT_CART_DETAILS_ACTION,
} from '../../../components/Checkout/redux/actions/checkout.actions';
import {
  DELIVERY_ASSIGN_STORE_SUCCESS_ACTION,
  PAYMENT_ASSIGN_STORE_SUCCESS_ACTION,
} from '../../actions/storePickup.actions';
import { setLocalStorage } from '../../../utils/utils';

export function* assignStoreSaga(action: any): Generator {
  try {
    const response = yield call(
      new StorePickupService().assignStore,
      action.payload
    );
    let storeObject: { d365StoreId?: string; message?: string } = {};
    storeObject = response as { d365StoreId?: string; message?: string };
    const storeId = storeObject?.d365StoreId ?? 'null';
    window.localStorage.setItem('D365', storeId);

    if (response) {
      yield put(
        FETCH_CHECKOUT_CART_DETAILS_ACTION({
          storeID: action.payload.storeID,
          checkoutFlow: action.payload.checkoutFlow,
          deliveryApiLoader: true,
          history: action.payload.history,
        })
      );
    }

    const storePickupFlag =
      action.payload?.isBOPIS && !action.payload.x_estimatedDelivery
        ? 'BOPIS'
        : action.payload?.isFSD && action.payload.x_estimatedDelivery
        ? 'FSD'
        : '';

    setLocalStorage('storePickup', storePickupFlag);

    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(
        DELIVERY_ASSIGN_STORE_SUCCESS_ACTION({
          storeLocatorId: action.payload.storeLocatorId,
          orderID: action.payload.orderID,
          storeID: action.payload.storeID,
          storeName: action.payload.storeName,
          isFSD: action.payload.isFSD,
          isBOPIS: action.payload.isBOPIS,
          checkoutFlow: action.payload.checkoutFlow,
          x_estimatedDelivery: action.payload.x_estimatedDelivery,
        })
      );
    } else if (action.payload.checkoutFlow === CheckoutFlows.Payment) {
      yield put(
        PAYMENT_ASSIGN_STORE_SUCCESS_ACTION({
          storeLocatorId: action.payload.storeLocatorId,
          orderID: action.payload.orderID,
          storeID: action.payload.storeID,
          storeName: action.payload.storeName,
          isFSD: action.payload.isFSD,
          isBOPIS: action.payload.isBOPIS,
          checkoutFlow: action.payload.checkoutFlow,
          x_estimatedDelivery: action.payload.x_estimatedDelivery,
        })
      );
    }
  } catch (error) {
    yield put(DELIVERY_PAGE_API_LOADING(false));
    throw error;
  }
}
