import { Paper, Typography } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IUtagData } from '../../utils/utils';
import { RobotsMetaTagsNoFollow } from '../Helmet/RobotsMetaTags/RobotsMetaTagsNoFollow';
import { NteAlert } from '../NteAlert/NteAlert';
import { useUtagAnalytics } from '../TealiumUtag/hooks/useUtagAnalytics';
import { ApiDetailsErrorWrapper } from './ApiDetailsErrorWrapper';
import { ContactUsforHelp } from './ContactUsforHelp';
import { ErrorConstants } from './ErrorConstants';
import { useAdobeTarget } from '../../utils/useAdobeTarget';

/**
 * @interface IError
 */
interface IError {
  errorDetails?: string | object;
  api?: string;
  apiResponsePayload?: string;
  apiResponseHttpCode?: string;
  htmlMarkup?: string;
  triggerLOLog?: boolean;
}

/**
 * @component Error renders the error card.
 *
 * @param IError
 */
const Error: React.FC<IError> = ({
  errorDetails,
  htmlMarkup,
  triggerLOLog = true,
  ...apiDetailsError
}) => {
  const { ERROR_TITLE, TEALIUM_ERROR_PAGE_EVENT, ERROR_TITLE_FALLBACK } =
    ErrorConstants;

  const { t, i18n } = useTranslation();

  const { pushPageViewForUtag } = useUtagAnalytics();

  const { triggerTargetView } = useAdobeTarget();

  /**
   * @callback initCategoryPageUtag Initializes the Utags for the Category pages.
   */
  const initErrorPageUtag = useCallback((): void => {
    const utagErrorPayload: IUtagData = {
      pageName: TEALIUM_ERROR_PAGE_EVENT.PAGE_NAME,
      pageType: TEALIUM_ERROR_PAGE_EVENT.PAGE_TYPE,
      pageSiteSection: TEALIUM_ERROR_PAGE_EVENT.PAGE_SITE_SECTION,
    };

    pushPageViewForUtag(utagErrorPayload);
    triggerTargetView(utagErrorPayload.pageName);
  }, [TEALIUM_ERROR_PAGE_EVENT.PAGE_NAME, TEALIUM_ERROR_PAGE_EVENT.PAGE_SITE_SECTION, TEALIUM_ERROR_PAGE_EVENT.PAGE_TYPE, pushPageViewForUtag, triggerTargetView]);

  useEffect(() => {
    initErrorPageUtag();
  }, [initErrorPageUtag]);

  const title = i18n.exists(ERROR_TITLE).valueOf()
    ? t(ERROR_TITLE)
    : ERROR_TITLE_FALLBACK;

  return (
    <section className='error page-row-margin-top  page-row'>
      <RobotsMetaTagsNoFollow addPrerenderTag />

      <Paper className='nte-card elevate-1'>
        <Typography id='API_Error_Details' variant='h2'>
          {title}
        </Typography>

        {errorDetails && (
          <NteAlert
            messages={
              typeof errorDetails === 'string'
                ? [t(errorDetails)]
                : [errorDetails]
            }
            showClose={false}
            severity='error'
          />
        )}

        {htmlMarkup && (
          <NteAlert
            messages={[]}
            showClose={false}
            severity='error'
            htmlMarkup={htmlMarkup}
          />
        )}

        <div className='cg-cols-2'>
          <ContactUsforHelp />

          {apiDetailsError && (
            <ApiDetailsErrorWrapper
              {...apiDetailsError}
              triggerLOLog={triggerLOLog}
            />
          )}
        </div>
      </Paper>
    </section>
  );
};

export { Error };
