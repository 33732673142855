import { format, isPast } from 'date-fns';
import { greaterThan365 } from '../../../../../utils/utils';
import {
  AnchorStore,
  GlobalBopis,
  LineType,
  ShipMethod,
  ShippingType,
  SoldAtStores,
  StockStatus,
  StockStatusAttribute,
  StoreBopis,
  StoreInventory,
  StoreOnly,
  StoreStatus,
  StoreStocked,
} from '../../../../../_foundation/enum/ProductList/ProductList';

const PRICING_KEY = 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.PRICING';

const ProductCardSectionMessagesConstants = {
  DISPLAY_PRICE: 'Display',
  OFFER_PRICE: 'Offer',
  SHIPPING_MESSAGE_PLP: {
    [StockStatus.InStock + ShippingType.STANDARD_GROUND_SHIPPING]:
      'Available now',
    [StockStatus.InStock + ShippingType.STANDARD_GROUND_FACTORY_SHIPPING]:
      'Factory shipped',
    [StockStatus.Backordered + ShippingType.STANDARD_GROUND_SHIPPING]:
      'Backordered',
    [StockStatus.InStock + ShippingType.FREE_STANDARD_GROUND_SHIPPING]:
      'Free shipping - Available now',
    [StockStatus.InStock + ShippingType.FREE_STANDARD_GROUND_FACTORY_SHIPPING]:
      'Free shipping - Factory shipped',
    [StockStatus.Backordered + ShippingType.FREE_STANDARD_GROUND_SHIPPING]:
      'Free shipping - Backordered',
    [StockStatus.InStock + ShippingType.STANDARD_TRUCK_SHIPPING]:
      'Available now',
    [StockStatus.InStock + ShippingType.STANDARD_TRUCK_FACTORY_SHIPPING]:
      'Factory shipped',
    [StockStatus.Backordered + ShippingType.STANDARD_TRUCK_SHIPPING]:
      'Backordered',
    [StockStatus.InStock + ShippingType.FREE_TRUCK_SHIPPING]:
      'Free shipping - Available now',
    [StockStatus.InStock + ShippingType.FREE_TRUCK_FACTORY_SHIPPING]:
      'Free shipping - Factory shipped',
    [StockStatus.Backordered + ShippingType.FREE_TRUCK_SHIPPING]:
      'Free shipping - Backordered',
    [StockStatus.NonStock + ShippingType.US_MAIL]:
      'Free shipping - Available now',
    [ShippingType.ELECTRONIC]: 'Available within 24 hours',
    [ShippingType.ELECTRONIC_FULFILLMENT_ADVANTAGE]:
      'Available within 24 hours',
    [ShippingType.ELECTRONIC_FULFILLMENT_EGIFTS]: 'Available within 24 hours',
    [ShippingType.ELECTRONIC_FULFILLMENT_WARRANTY]: 'Available within 24 hours',
    [ShippingType.US_MAIL]: 'Free shipping (lower 48 states) - Available now',
    [StockStatus.Backordered + ShippingType.PREORDER]:
      'Available in business days',
    [StockStatus.Backordered + ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE]:
      'Sold Out',
    [ShippingType.NOT_AVAILABLE]: 'Not Available',
  },
  SHIPPING_MESSAGE_PDP: {
    [StockStatus.InStock + ShippingType.STANDARD_GROUND_SHIPPING]:
      'Available now',
    [StockStatus.InStock + ShippingType.STANDARD_GROUND_FACTORY_SHIPPING]:
      'Factory shipped',
    [StockStatus.Backordered + ShippingType.STANDARD_GROUND_SHIPPING]:
      'Backordered - Expected to ship [X]',
    [StockStatus.InStock + ShippingType.FREE_STANDARD_GROUND_SHIPPING]:
      'Free shipping (lower 48 states) - Available now',
    [StockStatus.InStock + ShippingType.FREE_STANDARD_GROUND_FACTORY_SHIPPING]:
      'Free shipping (lower 48 states) - Factory shipped',
    [StockStatus.Backordered + ShippingType.FREE_STANDARD_GROUND_SHIPPING]:
      'Free shipping (lower 48 states) - Backordered - Expected to ship [X]',
    [StockStatus.InStock + ShippingType.STANDARD_TRUCK_SHIPPING]:
      'Available now',
    [StockStatus.InStock + ShippingType.STANDARD_TRUCK_FACTORY_SHIPPING]:
      'Factory shipped',
    [StockStatus.Backordered + ShippingType.STANDARD_TRUCK_SHIPPING]:
      'Backordered',
    [StockStatus.InStock + ShippingType.FREE_TRUCK_SHIPPING]:
      'Free shipping (lower 48 states) - Available now',
    [StockStatus.InStock + ShippingType.FREE_TRUCK_FACTORY_SHIPPING]:
      'Free shipping (lower 48 states) - Factory shipped',
    [StockStatus.Backordered + ShippingType.FREE_TRUCK_SHIPPING]:
      'Free shipping (lower 48 states) - Backordered - Expected to ship [X]',
    [StockStatus.NonStock + ShippingType.US_MAIL]:
      'Free shipping (lower 48 states) - Available now',
    [ShippingType.US_MAIL]: 'Free shipping (lower 48 states) - Available now',
    [ShippingType.ELECTRONIC]: 'Available within 24 hours',
    [ShippingType.ELECTRONIC_FULFILLMENT_ADVANTAGE]:
      'Available within 24 hours',
    [ShippingType.ELECTRONIC_FULFILLMENT_EGIFTS]: 'Available within 24 hours',
    [ShippingType.ELECTRONIC_FULFILLMENT_WARRANTY]: 'Available within 24 hours',
    [StockStatus.Backordered + ShippingType.PREORDER]:
      'Available in business days',
    [StockStatus.Backordered + ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE]:
      'Sold Out',
    [ShippingType.NOT_AVAILABLE]: 'Not Available',
    [StockStatus.NotAvailable + ShippingType.FREE_STANDARD_GROUND_SHIPPING]:
      'Free shipping (lower 48 states)',
    [StockStatus.NotAvailable + ShippingType.STANDARD_GROUND_FACTORY_SHIPPING]:
      'Factory shipped',
    [StockStatus.NotAvailable +
    ShippingType.FREE_STANDARD_GROUND_FACTORY_SHIPPING]:
      'Free shipping (lower 48 states) - Factory shipped',
  },
  SHIPPING_TYPE_VALUE: {
    [LineType.S + StockStatusAttribute.S]:
      ShippingType.STANDARD_GROUND_SHIPPING,
    [LineType.S + StockStatusAttribute.J]:
      ShippingType.STANDARD_GROUND_SHIPPING,
    [LineType.S + StockStatusAttribute.U]:
      ShippingType.STANDARD_GROUND_SHIPPING,
    [LineType.S + StockStatusAttribute.Z]:
      ShippingType.STANDARD_GROUND_SHIPPING,
    [LineType.D + StockStatusAttribute.S]:
      ShippingType.STANDARD_GROUND_FACTORY_SHIPPING,
    [LineType.D + StockStatusAttribute.J]:
      ShippingType.STANDARD_GROUND_FACTORY_SHIPPING,
    [LineType.D + StockStatusAttribute.U]:
      ShippingType.STANDARD_GROUND_FACTORY_SHIPPING,
    [LineType.D + StockStatusAttribute.Z]:
      ShippingType.STANDARD_GROUND_FACTORY_SHIPPING,

    [LineType.S2 + StockStatusAttribute.S]:
      ShippingType.FREE_STANDARD_GROUND_SHIPPING,
    [LineType.S2 + StockStatusAttribute.J]:
      ShippingType.FREE_STANDARD_GROUND_SHIPPING,
    [LineType.S2 + StockStatusAttribute.U]:
      ShippingType.FREE_STANDARD_GROUND_SHIPPING,
    [LineType.S2 + StockStatusAttribute.Z]:
      ShippingType.FREE_STANDARD_GROUND_SHIPPING,
    [LineType.D1 + StockStatusAttribute.S]:
      ShippingType.FREE_STANDARD_GROUND_FACTORY_SHIPPING,
    [LineType.D1 + StockStatusAttribute.J]:
      ShippingType.FREE_STANDARD_GROUND_FACTORY_SHIPPING,
    [LineType.D1 + StockStatusAttribute.U]:
      ShippingType.FREE_STANDARD_GROUND_FACTORY_SHIPPING,
    [LineType.D1 + StockStatusAttribute.Z]:
      ShippingType.FREE_STANDARD_GROUND_FACTORY_SHIPPING,

    [LineType.S + ShipMethod.Truck + StockStatusAttribute.S]:
      ShippingType.STANDARD_TRUCK_SHIPPING,
    [LineType.S + ShipMethod.Truck + StockStatusAttribute.J]:
      ShippingType.STANDARD_TRUCK_SHIPPING,
    [LineType.S + ShipMethod.Truck + StockStatusAttribute.U]:
      ShippingType.STANDARD_TRUCK_SHIPPING,
    [LineType.S + ShipMethod.Truck + StockStatusAttribute.Z]:
      ShippingType.STANDARD_TRUCK_SHIPPING,
    [LineType.D + ShipMethod.Truck + StockStatusAttribute.S]:
      ShippingType.STANDARD_TRUCK_FACTORY_SHIPPING,
    [LineType.D + ShipMethod.Truck + StockStatusAttribute.J]:
      ShippingType.STANDARD_TRUCK_FACTORY_SHIPPING,
    [LineType.D + ShipMethod.Truck + StockStatusAttribute.U]:
      ShippingType.STANDARD_TRUCK_FACTORY_SHIPPING,
    [LineType.D + ShipMethod.Truck + StockStatusAttribute.Z]:
      ShippingType.STANDARD_TRUCK_FACTORY_SHIPPING,

    [LineType.S2 + ShipMethod.Truck + StockStatusAttribute.S]:
      ShippingType.FREE_TRUCK_SHIPPING,
    [LineType.S2 + ShipMethod.Truck + StockStatusAttribute.J]:
      ShippingType.FREE_TRUCK_SHIPPING,
    [LineType.S2 + ShipMethod.Truck + StockStatusAttribute.U]:
      ShippingType.FREE_TRUCK_SHIPPING,
    [LineType.S2 + ShipMethod.Truck + StockStatusAttribute.Z]:
      ShippingType.FREE_TRUCK_SHIPPING,
    [LineType.D1 + ShipMethod.Truck + StockStatusAttribute.S]:
      ShippingType.FREE_TRUCK_FACTORY_SHIPPING,
    [LineType.D1 + ShipMethod.Truck + StockStatusAttribute.J]:
      ShippingType.FREE_TRUCK_FACTORY_SHIPPING,
    [LineType.D1 + ShipMethod.Truck + StockStatusAttribute.U]:
      ShippingType.FREE_TRUCK_FACTORY_SHIPPING,
    [LineType.D1 + ShipMethod.Truck + StockStatusAttribute.Z]:
      ShippingType.FREE_TRUCK_FACTORY_SHIPPING,

    [LineType.NS + ShipMethod.USPS]: ShippingType.US_MAIL,
    [LineType.NS + ShipMethod.Electronic]: ShippingType.ELECTRONIC,

    [LineType.S + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.S + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.S + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.S + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,

    [LineType.S2 + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.S2 + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.S2 + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.S2 + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,

    [LineType.D + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.D + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.D + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.D + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,

    [LineType.D1 + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.D1 + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.D1 + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.D1 + StockStatusAttribute.O]:
      ShippingType.SOLD_OUT_NO_LONGER_AVAILABLE,
    [LineType.S2]: ShippingType.FREE_STANDARD_GROUND_SHIPPING,
    [LineType.D]: ShippingType.STANDARD_GROUND_FACTORY_SHIPPING,
    [LineType.D1]: ShippingType.FREE_STANDARD_GROUND_FACTORY_SHIPPING,
  },
  STORE_PICKUP_PLP: {
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open]: '',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open]: '',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: '',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: `Not sold at Store-Name`,
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'Not available',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Not available - online only',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: `Not available - online only`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: '',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: '',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open]: 'Sold only in stores - ',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Sold only in stores - ',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open]: 'Sold only in stores - ',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Sold only in stores - ',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: `Not available at Store-Name`,

    [AnchorStore.NotSelected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'No stores near you - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'No stores near you - ',
    [AnchorStore.NotSelected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'No stores near you - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Not available - online only',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Not available',
    [AnchorStore.NotSelected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'No stores near you - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'Sold only in stores - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'Sold only in stores - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Close]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Close]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Close]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Not sold at Store-Name',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: '',
  },
  STORE_PICKUP_PDP_CART: {
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.NA]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: 'Not available - online only',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.NA]: `Not sold at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `Not sold at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: `Not sold at Store-Name`,
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: `Not Available`,
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.GreaterThanZero]: `Not Available`,
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.LesserThanEqualToZero]: `Not Available`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: `Out of stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: `Out of stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `X in stock at Store-Name - Pickup in under 1 hr`,
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `Sold only in stores - X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `Sold only in stores - X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `Sold only in stores - X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `Sold only in stores - X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: `Sold only in stores - Out of stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: `Sold only in stores - Out of stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: `Sold only in stores - Out of stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: `Sold only in stores - Out of stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: `Not available at Store-Name`,

    [AnchorStore.NotSelected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: 'No stores near you - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: 'No stores near you - ',
    [AnchorStore.NotSelected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: 'No stores near you - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.NA]: 'Not available - online only',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.NA]: 'Not available',
    [AnchorStore.NotSelected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: 'No stores near you - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: 'Sold only in stores - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.StoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: 'Sold only in stores - ',
    [AnchorStore.NotSelected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.NA]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Close +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Close +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Close +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Close +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Close +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Close +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Close +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.NotStoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.SoldAtStores +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.NotGlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: 'Not available - online only',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.NotStoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Not sold at [Store-Name]',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open]: 'Not sold at Store-Name',
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.GreaterThanZero]: `X in stock at Store-Name`,
    [AnchorStore.Selected +
    SoldAtStores.NotSoldAtMyStore +
    StoreOnly.NotStoreOnly +
    StoreStocked.StoreStocked +
    GlobalBopis.GlobalBopis +
    StoreBopis.StoreBopis +
    StoreStatus.Open +
    StoreInventory.LesserThanEqualToZero]: `Out of stock at Store-Name`,
  },
  SAME_DAY_DELIVERY_MESSAGE: '$19.99 Same Day',
  SHIPPING_INFO_MESSAGE:
    'Shipping dates are estimates and could change due to vendor availability.',
  SHIPPING_TYPE_IDENTIFIER: 'ShippingType',
  SOLD_AT_STORE_IDENTIFIER: 'sold-at-store',
  BOPIS_AVAILABILITY: 'BopisAvailability',
  LOCAL_DELIVERY: 'LocalDelivery',
  STORE_STOCKED: 'storestocked',
  STORE_WEB_STATUS: 'StoreWebStatus',
  LINE_TYPE_IDENTIFIER: 'linetype',
  LINE_TYPE_VALUE_D1: 'd1',
  LINE_TYPE_VALUE_D: 'd',
  LINE_TYPE_VALUE_S: 's2',
  LINE_TYPE_VALUE_NS: 'ns',
  SHIP_METHOD_IDENTIFIER: 'shipmethod',
  TRUCK_SHIP_METHOD: 'truck',
  STORE_ONLY_IDENTIFIER: 'storeonly',
  STOCK_STATUS_IDENTIFIER: 'stockstatus',
  PROMOTION_IDENTIFIER: 'Promotion',
  ADVANTAGE_EXCLUSIVE_IDENTIFIER: 'Advantage Exclusives',
  NOT_AVAILABLE_MESSAGE: 'Not available',
  DISPLAY_PRICE_IDENTIFIER: 'Display',
  OFFER_PRICE_IDENTIFIER: 'Offer',
  QUANTITY_PRICING: `${PRICING_KEY}.QUANTITY_PRICING`,
  REGULAR_SAVE_PRICING: `${PRICING_KEY}.REGULAR_SAVE_PRICING`,
  REG: `${PRICING_KEY}.REG`,
  SAVE: `${PRICING_KEY}.SAVE`,
  REGULAR_PRICE: `${PRICING_KEY}.REGULAR_PRICE`,
  FROM_REGULAR_PRICE: `${PRICING_KEY}.FROM_REGULAR_PRICE`,
  ORDER_TODAY: `${PRICING_KEY}.ORDER_TODAY`,
  FROM: `${PRICING_KEY}.FROM`,
  SAVE_UPTO: `${PRICING_KEY}.SAVE_UPTO`,
  SEE_QTY_PRICING: `${PRICING_KEY}.SEE_QTY_PRICING`,
  FREE: `${PRICING_KEY}.FREE`,
  NOT_AVAILABLE: 'not available',
  BACKORDERED: 'Backordered',
  SOLD_OUT: 'Sold Out',
  AVAILABLE_NOW: 'available now',
  FACTORY_SHIPPED: 'factory shipped',
  FREE_SHIPPING_AVAILABLE_NOW: 'Free shipping - Available now',
  FREE_SHIPPING_BACKORDERED: 'Free shipping (lower 48 states) - Backordered',
  FREE_SHIPPING_LOWER_48_STATES: 'Free shipping (lower 48 states)',
  AVAILABLE_WITHIN_24_HOURS: 'Available within 24 hours',
  AVAILABLE_BUSINESS_DAYS: 'Available in [X] business days',
  TRUCK_DELIVERY_MESSAGE: 'Heavy/large item. Lift gate service available.',
  FREE_SHIPPING: 'Free Shipping ',
  SEPERATOR: ' - ',
  ProductShippingConstants: {
    MESSAGES: {
      GREEN_COLOR: 'color-green',
      GREY_COLOR: 'color-gray',
      RED_COLOR: 'color-red',
      DEFAULT_COLOR: 'color-default',
    },
  },
  CART: 'cart',
  SEE_SHIPPING_OPTIONS: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SEE_SHIPPING_OPTIONS',
  SHIPPING_MESSAGE_UNAVAILABLE:
    'PRODUCT_LIST_PAGE.PRODUCT_CARD.SHIPPING_MESSAGE_UNAVAILABLE',
  STORE_PICKUP_MESSAGE_UNAVAILABLE:
    'PRODUCT_LIST_PAGE.PRODUCT_CARD.STORE_PICKUP_MESSAGE_UNAVAILABLE',
  SEE_DETAILS: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.SEE_DETAILS',
  CALL_STORE: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.CALL_STORE',
  SALE_PRICE_CART:
    'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.PRICING.SALE_PRICE_CART',
  CART_PAGE_TYPE: 'CartPage',
  PLP_PAGE_TYPE: 'ProductListPage',
  CATEGORY_PAGE: 'CategoryPage',
  PRODUCT_PAGE: 'ProductPage',
  CONTENT_PAGE: 'ContentPage',
  ITEM_PAGE: 'ItemPage',
  CHECK_STORES_PICKUP:
    'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.CHECK_STORES_PICKUP',
  NA: 'N/A',
  STORE_PICKUP_NOT_AVAILABLE: 'Not Available ',
  ELECTRONIC_KEYWORD: 'electronic',
  STORE_STATUS: {
    OPEN: 'Open',
    NOW_OPEN: 'Now Open',
    TEMPORARILY_CLOSED: 'Temporarily Closed',
    PERMANENTLY_CLOSED: 'Permanently Closed',
    COMING_SOON: 'Coming Soon',
  },
  STORE_WEB_STATUS_IDENTIFIER: 'storeWebStatus',
  D365_ATTRIBUTE: 'operatingInD365',
  GIFTCARD_PAY_METHOD: 'GiftCard',
  NOT_AVAILABLE_AT: (storeName: string) => `Not available at ${storeName}`,
  SHOW_CALL_STORE_BRAND: [''],
};

/**
 * @function getShippingMessage returns shipping message with the availability date
 */
const getShippingMessage = (shippingMessage: string, date?: string) => {
  let year: string = '';

  if (date && greaterThan365(date)) {
    year = `, ${new Date(date).getFullYear()}`;
  }

  switch (shippingMessage) {
    case ProductCardSectionMessagesConstants.SHIPPING_MESSAGE_PLP[
      StockStatus.Backordered + ShippingType.PREORDER
    ]:
      return (
        'Backordered' +
        `${
          date && !isPast(new Date(date))
            ? ` - Expected to ship ${format(
                new Date(date),
                'eee, LLL d'
              )}${year}`
            : ''
        }`
      );

    case ProductCardSectionMessagesConstants.SHIPPING_MESSAGE_PDP[
      StockStatus.Backordered + ShippingType.STANDARD_GROUND_SHIPPING
    ]:
      return 'Backordered'+ `${
      date && !isPast(new Date(date))
        ? ` - Expected to ship ${format(new Date(date), 'eee, LLL d')}${year}`
        : ''
    }`;

    case ProductCardSectionMessagesConstants.SHIPPING_MESSAGE_PDP[
      StockStatus.Backordered + ShippingType.FREE_STANDARD_GROUND_SHIPPING
    ]:
      return 'Free shipping (lower 48 states) - Backordered' +`${
        date && !isPast(new Date(date))
        ? ` - Expected to ship ${format(new Date(date), 'eee, LLL d')}${year}`
        : ''
        }`;

    case ProductCardSectionMessagesConstants.SHIPPING_MESSAGE_PDP[
      StockStatus.Backordered + ShippingType.FREE_TRUCK_SHIPPING
    ]:
      return 'Free shipping (lower 48 states) - Backordered'+`${
      date && !isPast(new Date(date))
        ? ` - Expected to ship ${format(new Date(date), 'eee, LLL d')}${year}`
        : ''
    }`;


    case ProductCardSectionMessagesConstants.SHIPPING_MESSAGE_PDP[
      StockStatus.Backordered + ShippingType.STANDARD_TRUCK_SHIPPING
    ]:
      return 'Backordered'+`${
      date && !isPast(new Date(date))
        ? ` - Expected to ship ${format(new Date(date), 'eee, LLL d')}${year}`
        : ''
    }`;

    default:
      return shippingMessage;
  }
};

export { ProductCardSectionMessagesConstants, getShippingMessage };
