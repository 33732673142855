import { Grid, Hidden, Toolbar, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HOME } from '../../../../constants/routes';
import CheckoutHeader from '../../../Checkout/CheckoutHeader/CheckoutHeader';
import { useCheckout } from '../../../Checkout/hooks/CheckoutHooks';
import Logo from '../../Logo';
import LogoText from '../../LogoText';
import { NtePrimarySearchBar } from '../../SearchBar/CustomSearchBar/NtePrimarySearchBar';
import { NavBarHamburgerMenu } from '../PrimaryMenuItems/NavBarMenuItem/NavBarHamburgerMenu/NavBarHamburgerMenu';
import { NavBarMenu } from '../PrimaryMenuItems/NavBarMenuItem/NavBarMenu';
import { useAdobeTarget } from '../../../../utils/useAdobeTarget';
import { Skeleton } from '@material-ui/lab';

/**
 * @component PrimaryNavBar renders the Primary NavBar
 */
const PrimaryNavBar: React.FC = () => {
  const { isCheckout } = useCheckout();

  const { updateTargetExperiances } = useAdobeTarget();

  const [gotSearchGroup, setGotSearchGroup] = useState<boolean>(false);
  const [useVertex, setUseVertex] = useState<boolean>(false);

  const getSearchABGroup = (response: any) => {
    console.log("NavBarResult");
    console.log(response);
    if (response?.response?.execute?.pageLoad?.options) {
      const options: any[] = response.response.execute.pageLoad.options;
      console.log("options found");
      console.log(options);
      const foundExperiance = options.find((option) => option.responseTokens["experience.name"] === 'Vertex Search');
      console.log("foundExperiance:" + foundExperiance);
      setUseVertex(foundExperiance !== undefined);
      options.forEach((option) => {
        console.log(option.responseTokens["experience.name"]);
      });
    } else {
      console.log("no options found");
    }
    setGotSearchGroup(true);
  }

  /**
   * @method initTargetPDP Sends current PDP page state (brand, product#, etc to adobe target)
   */
  const initSearchBar = useCallback((): void => {
    if (!isCheckout && !gotSearchGroup) {
      const params = {
        isCheckout: true,
      }
      updateTargetExperiances(params, {}, getSearchABGroup);
    }
  }, [gotSearchGroup, isCheckout, updateTargetExperiances]);

  useEffect(() => {
    initSearchBar();
  }, [initSearchBar]);

  return (
    <Toolbar className={`primary nav_bar ${isCheckout && 'checkout'}`}>
      <Link
        aria-label='home link'
        rel='nofollow'
        to={HOME}
        className='no-link-effect home-link'>
        <Logo
          className='logo'
          aria-labelledby='nteLogoIconTitle nteLogoIconDesc'
          role={'img'}
        />

        <Hidden smDown>
          <LogoText
            className='logo_text'
            aria-labelledby='nteLogoTextTitle nteLogoTextDesc'
          />
        </Hidden>
      </Link>

      {isCheckout ? (
        <CheckoutHeader />
      ) : (
        <>
          <nav aria-label='primary'>
            <Hidden mdUp>
              <div className='hamburger-container'>
                <NavBarHamburgerMenu />
              </div>
            </Hidden>

            <Grid
              className='top-level-menu-list'
              component='ul'
              container
              alignItems='center'
              justifyContent='flex-start'>
              <Hidden smDown>
                <NavBarMenu />
              </Hidden>
            </Grid>
          </nav>

          {!gotSearchGroup ? (
            <div className={`nte_primary_search_bar color-white`}>
              <Skeleton />
            </div>
          ) : (
            <>
              {useVertex ? (
                <Typography className='color-white'>I'm your new searchbar!</Typography>
              ) : (
                <NtePrimarySearchBar />
              )}
            </>
          )}
        </>
      )}
    </Toolbar>
  );
};

export { PrimaryNavBar };
