import { createAction } from '@reduxjs/toolkit';
import * as ACTION_TYPES from '../action-types/site';

/**
 * @interface IUpdateLastVisitedAction
 */
interface IUpdateLastVisitedAction {
  lastVisitedUrl: string;
}

export const INIT_SITE_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.INIT_SITE_SUCCESS
);

export const INIT_SITE_LOADING_ACTION = createAction<any, string>(
  ACTION_TYPES.INIT_SITE_LOADING
);

export const INIT_SITE_FAILURE_ACTION = createAction(
  ACTION_TYPES.INIT_SITE_FAILURE
);

export const UPDATE_LAST_VISITED_URL_ACTION =
  createAction<IUpdateLastVisitedAction>(ACTION_TYPES.UPDATE_LAST_VISITED_URL);
