import { createAction } from '@reduxjs/toolkit';
import { IGetCartDetails } from '../../../../_foundation/interface/Cart/IOrder';
import ICartProductsResponse from '../../../../_foundation/interface/Responses/ICartProductsResponse';
import IGetUsableShippingResponse from '../../../../_foundation/interface/Responses/IGetUsableShippingResponse';
import { IShippingMethods } from '../../../../_foundation/interface/Responses/IShippingInfoResponse';
import { StoreDetails } from '../../../../_foundation/interface/StoreLocator/IStoreLocator';
import {
  ICardinalOrderRequest,
  ICardinalOrderResponse,
  ITreviPayAuthenticateRequest,
  ITreviPayAuthenticateResponse,
} from '../../interface/ICheckoutState';
import * as ACTION_TYPES from '../action-types/checkout.action-types';

/**
 * Common cart calls in checkout flow.
 */
export const FETCH_CHECKOUT_CART_DETAILS_ACTION = createAction<IGetCartDetails>(
  ACTION_TYPES.FETCH_CHECKOUT_CART_DETAILS
);

export const FETCH_CHECKOUT_CART_DETAILS_DELIVERY = createAction<any>(
  ACTION_TYPES.FETCH_CHECKOUT_CART_DETAILS_DELIVERY
);

export const DELIVERY_PAGE_API_LOADING = createAction<boolean>(
  ACTION_TYPES.DELIVERY_PAGE_API_LOADING
);

export const FETCH_CHECKOUT_CART_DETAILS_PAYMENT = createAction<any>(
  ACTION_TYPES.FETCH_CHECKOUT_CART_DETAILS_PAYMENT
);

export const FETCH_CHECKOUT_CART_DETAILS_REVIEW = createAction<any>(
  ACTION_TYPES.FETCH_CHECKOUT_CART_DETAILS_REVIEW
);

export const UPDATE_DELIVERY_SHIPPING_METHOD_DATA_ACTION =
  createAction<IShippingMethods>(
    ACTION_TYPES.UPDATE_DELIVERY_SHIPPING_METHOD_DATA
  );

export const SET_UPDATE_DELIVERY_SHIPPING_METHOD_LOADING_ACTION = createAction<{
  isAddressUpdate?: boolean;
  updateShippingMethodLoading?: boolean;
}>(ACTION_TYPES.SET_UPDATE_DELIVERY_SHIPPING_METHOD_LOADING);

export const DELIVERY_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION =
  createAction<StoreDetails>(
    ACTION_TYPES.DELIVERY_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS
  );

export const SELECTED_ADDRESS_NICK_NAME_ACTION = createAction<string>(
  ACTION_TYPES.SELECTED_ADDRESS_NICK_NAME
);

export const RESET_SHIPPING_OPTIONS_ACTION = createAction(
  ACTION_TYPES.RESET_SHIPPING_OPTIONS
);

export const RESET_DELIVERY_STEP_ACTION = createAction(
  ACTION_TYPES.RESET_DELIVERY_STEP
);

export const DELIVERY_USABLE_SHIPPING_INFO_SUCCESS_ACTION =
  createAction<IGetUsableShippingResponse>(
    ACTION_TYPES.DELIVERY_USABLE_SHIPPING_INFO_SUCCESS
  );

export const DELIVERY_USABLE_SHIPPING_INFO_LOADING_ACTION =
  createAction<boolean>(ACTION_TYPES.DELIVERY_USABLE_SHIPPING_INFO_LOADING);

export const RESTRICTED_CART_PRODUCTS_SUCCESS_ACTION =
  createAction<ICartProductsResponse>(
    ACTION_TYPES.RESTRICTED_CART_PRODUCTS_SUCCESS
  );

export const ENABLE_DELIVERY_CONTINUE_ACTION = createAction<boolean>(
  ACTION_TYPES.ENABLE_DELIVERY_CONTINUE
);

export const RESET_RESTRICTED_PRODUCTS_ACTION = createAction(
  ACTION_TYPES.RESET_RESTRICTED_PRODUCTS
);

export const PAYMENT_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION =
  createAction<StoreDetails>(
    ACTION_TYPES.PAYMENT_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS
  );

export const PAYMENT_USABLE_SHIPPING_INFO_SUCCESS_ACTION =
  createAction<IGetUsableShippingResponse>(
    ACTION_TYPES.PAYMENT_USABLE_SHIPPING_INFO_SUCCESS
  );

export const ENABLE_PAYMENT_CONTINUE_ACTION = createAction<boolean>(
  ACTION_TYPES.ENABLE_PAYMENT_CONTINUE
);

export const RESET_PAYMENT_STEP_ACTION = createAction(
  ACTION_TYPES.RESET_PAYMENT_STEP
);

export const FETCH_CART_PRODUCTS_REVIEW_ACTION =
  createAction<ICartProductsResponse>(ACTION_TYPES.FETCH_CART_PRODUCTS_REVIEW);

export const REVIEW_USABLE_SHIPPING_INFO_SUCCESS_ACTION =
  createAction<IGetUsableShippingResponse>(
    ACTION_TYPES.REVIEW_USABLE_SHIPPING_INFO_SUCCESS
  );

export const UPDATE_REVIEW_SHIPPING_METHOD_DATA_ACTION =
  createAction<IShippingMethods>(
    ACTION_TYPES.UPDATE_REVIEW_SHIPPING_METHOD_DATA
  );

export const RESET_REVIEW_STEP_ACTION = createAction(
  ACTION_TYPES.RESET_REVIEW_STEP
);

export const CARDINAL_ORDER_REQUEST_ACTION =
  createAction<ICardinalOrderRequest>(ACTION_TYPES.CARDINAL_ORDER_REQUEST);

export const CARDINAL_ORDER_REQUEST_SUCCESS_ACTION =
  createAction<ICardinalOrderResponse>(
    ACTION_TYPES.CARDINAL_ORDER_REQUEST_SUCCESS
  );

export const CARD_DENIED_ACTION = createAction<boolean>(
  ACTION_TYPES.CARD_DENIED
);

export const RESET_ASSIGN_STORE_ACTION = createAction(
  ACTION_TYPES.RESET_ASSIGN_STORE_ACTION_SUCCESS
);

export const PAYPAL_PROCESS_LOADING_ACTION = createAction<boolean>(
  ACTION_TYPES.PAYPAL_PROCESS_LOADING
);

export const TREVIPAY_AUTHENTICATE_REQUEST_ACTION =
  createAction<ITreviPayAuthenticateRequest>(
    ACTION_TYPES.TREVIPAY_AUTHENTICATE_REQUEST
  );

export const TREVIPAY_AUTHENTICATE_SUCCESS_ACTION =
  createAction<ITreviPayAuthenticateResponse>(
    ACTION_TYPES.TREVIPAY_AUTHENTICATE_REQUEST_SUCCESS
  );

export const UPDATE_EMAIL_OPTIN_STATUS = createAction<boolean>(
  ACTION_TYPES.UPDATE_EMAIL_OPTIN_STATUS
);
