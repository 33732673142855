import { StylesProvider } from '@material-ui/core/styles';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/rias/ls.rias';
import React, { Dispatch, useCallback, useEffect, useRef } from 'react';
import { isIE, isLegacyEdge } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './App.scss';
import { AccountSignIn } from './components/AccountSignIn/AccountSignIn';
import { NteAppBar } from './components/AppBar/NteAppBar';
import BrowserAlert from './components/BrowserAlert/BrowserAlert';
import {
  COOKIE_CJEVENT_KEY,
  LOCALSTORAGE_CJEVENT_KEY,
  SESSION_STORAGE_KEY,
  SESSION_STORAGE_UTM_SOURCE_KEY,
} from './components/BrowserAlert/BrowserAlert.constants';
import NteFooter from './components/Footer/Footer';
import { RobotsMetaTags } from './components/Helmet/RobotsMetaTags/RobotsMetaTags';
import { RenderNteAlert } from './components/NteAlert/RenderNteAlert';
import { NteBackdrop } from './components/NteBackdrop/NteBackdrop';
import { NteRouter } from './components/NteRouter/NteRouter';
import { SessionInvalid } from './components/SessionInvalid/SessionInvalid';
import { LastVisitedRouteContext } from './components/TealiumUtag/hooks/useUtagAnalytics';
import { DealsSignup } from './components/Widgets/EdealsSignupModal/DealsSignup';
import { PwaInstallPrompt } from './components/Widgets/PwaInstallPrompt/PwaInstallPrompt';
import { ScrollToTop } from './components/Widgets/ScrollToTop/ScrollToTop';
import { SkipToMainContent } from './components/Widgets/SkipToMainContent/SkipToMainContent';
import './theme/scss/_mui_overrides.scss';
import { CookieUtility } from './utils/cookie-utility';
import { initForter } from './utils/forter';
import { useInitApplication } from './utils/hooks/init-application/init-application';
import { initializeAxios } from './_foundation/apis/axios/axiosConfig';
import { SESSION_ID, USER_IP } from './_foundation/constants/cookie';
import { site } from './_foundation/constants/site';
import { LoginGuard } from './_foundation/guard/LoginGuard';
import { initSite } from './_foundation/hooks/usesite';
import { initTarget } from './utils/at.js';
import { HeaderPromotionBanner } from './components/HeaderBanner/HeaderPromotionBanner';
import Axios from 'axios';

/**
 * App component for NTE react store application.
 */
const App: React.FC = () => {
  useInitApplication();

  /** This helps in preventing unnecessary duplicate Analytics data when routes are rendered more than once. */
  const lastVisitedRouteRef = useRef<string>();

  const dispatch = useDispatch<Dispatch<any>>();

  const search = useLocation().search;

  const isPrerender = (window as { [key: string]: any })['__isPrerender__'];

  const setSessionId = () => {
    const sessionIdCookie = CookieUtility.getCookie(SESSION_ID);

    sessionStorage.setItem(SESSION_STORAGE_KEY, sessionIdCookie);
  };

  const setSessionUtmSource = useCallback(() => {
    const utm_source = new URLSearchParams(search).get('utm_source');

    if (utm_source === 'QR') {
      sessionStorage.setItem(SESSION_STORAGE_UTM_SOURCE_KEY, utm_source);
    }
  }, [search]);

  const setCJEventCookie = useCallback(() => {
    const cjevent = new URLSearchParams(search).get('cjevent');

    if (cjevent) {
      //set cookie expiry to 395 days
      const expireDate = new Date();
      const time = expireDate.getTime();
      const expireTime = time + 395 * 24 * 60 * 60 * 1000;
      expireDate.setTime(expireTime);

      localStorage.setItem(LOCALSTORAGE_CJEVENT_KEY, cjevent);

      //setCookie util method is stringifying the cjevent text, need to manually setup cookie
      //document.cookie =
      //  COOKIE_CJEVENT_KEY + '=' + cjevent + '; expires = ' + expireDate + '; domain=northerntool.com; secure; samesite=none';
    }
  }, [search]);

  useEffect(() => {
    initializeAxios();

    setSessionId();

    setSessionUtmSource();
    setCJEventCookie();
  }, [setSessionUtmSource, setCJEventCookie]);

  useEffect(() => {
    initSite(site, dispatch);
  }, [dispatch]);

  useEffect(() => {
    /**
     * Initial forter call to set the forter cookie for the application.
     */
    initForter();
  }, []);
  const setUserIP = () => {
    const currentIP = sessionStorage.getItem(USER_IP);
    // Make a request for a user with a given ID
    if (currentIP === null) {
      Axios.get('https://www.cloudflare.com/cdn-cgi/trace').then(function (
        response
      ) {
        const userIp = response.data.split('\n')[2].replace('ip=', '');
        sessionStorage.setItem(USER_IP, userIp);
      });
    }
  };

  useEffect(() => {
    setUserIP();
  }, [setUserIP]);

  //temporarily disable this until we can come up with a better fix
  /*
  const { loading, isLatestVersion, refreshCacheAndReload } = useCacheBuster();

  useEffect(() => {
    if (!loading && !isLatestVersion) {
      refreshCacheAndReload();
    }
  }, []);
*/

  return (
    <LastVisitedRouteContext.Provider value={lastVisitedRouteRef}>
      <StylesProvider injectFirst>
        <RobotsMetaTags />

        <ScrollToTop />

        <SkipToMainContent />

        <PwaInstallPrompt />

        <NteAppBar />

        <LoginGuard />

        <main id='main-content' tabIndex={-1}>
          <HeaderPromotionBanner />

          <NteRouter />

          <NteBackdrop />
        </main>

        <AccountSignIn />

        <SessionInvalid />

        <RenderNteAlert />

        {!isPrerender && <DealsSignup />}

        {(isIE || isLegacyEdge) && <BrowserAlert />}

        <NteFooter />
      </StylesProvider>
    </LastVisitedRouteContext.Provider>
  );
};

// Export.
export default App;
