const CartItemCardConstants = {
  UPSELL: 'UPSELL',
  ACCESSORY: 'ACCESSORY',
  BUNDLE: 'BUNDLE',
  OFFER: 'offer',
  PRODUCT: 'product',
  WARRANTY: 'warranty',
  ITEM_TYPE: 'item-type',
  LINE_TYPE: 'linetype',
  LINE_TYPE_VALUES: ['D', 'D1'],
  STORE_PICKUP: 'Store Pickup: ',
  LIFTGATESERVICE: 'liftgateservice',
  FACTORY_SHIPPING: 'Shipping from Factory: ',
  SHIPPING: 'Shipping: ',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  YES: 'Y',
};

export { CartItemCardConstants };
