import { ADOBE_TARGET_CONSTANTS } from "../constants/AdobeTarget";

const useAdobeTarget = () => {

	const getTargetExperiance = (mboxName: string = ADOBE_TARGET_CONSTANTS.MBOX_NAMES.DEFAULT_MBOX, params: {}) => {
		adobe.target.getOffer({
			mbox: mboxName,
			params: params,
			success: function (offer: any) {
				adobe.target.applyOffer({
					mbox: mboxName,
					offer: offer
				});

				document.documentElement.style.opacity = "1";
			},
			error: function (e: any) {
				console.error(e);
				document.documentElement.style.opacity = "1";
			}
		});
	};

	const updateTargetExperiances = (params: {}, orderData: {}, successFunction: Function): void => {

		// const offerData = {
		// 	request: {
		// 		prefetch: {
		// 			views: [
		// 				{
		// 					parameters: params,
		// 				}
		// 			]
		// 		},
		// 	}
		// };
		const offerData = {
			request: {
				"execute": {
					"pageLoad": {
						"parameters": params,
					}
				},
			}
		};
		console.log("Getting offers");
		console.log(offerData);
		adobe.target.getOffers(offerData).then((response: any) => {
			console.log("Got response");
			console.log(response);
			adobe.target.applyOffers({ response: response })
				.then(successFunction({ response: response }));
		}).catch((error: any) => console.log("Error", error));
	};

	/**
	 * Triggers an adobe page view/update event.
	 * @param viewName - name of page view to trigger
	 * @param isPageTransition - view is a new page, set false if page url isn't changing.
	 */
	const triggerTargetView = (viewName: string, isPageTransition: boolean = true): void => {
		if (typeof adobe != 'object') {
			console.error('Adobe not loaded yet, cannot trigger view.');
		} else {
			if (adobe?.target?.triggerView) {
				console.log('trggering view!' + viewName);
				adobe?.target?.triggerView(viewName, { page: isPageTransition });
			} else {
				console.error(
					"Adobe and/or Adobe.target undefined. Can't trigger view rvi_ready"
				);
			}
		}
	};

	const triggerTargetViewWithParameters = (mboxName: string = ADOBE_TARGET_CONSTANTS.MBOX_NAMES.DEFAULT_MBOX, params: {}, viewName: string, isPageTransition: boolean = true): void => {
		if (typeof adobe != 'object') {
			console.error('Adobe not loaded yet, cannot trigger view.');
		} else {

			const offerData = {
				request: {
					"prefetch": {
						"mboxes": [
							{
								name: mboxName,
								index: 0,
								parameters: params,
							}
						],
						"pageLoad": {
							"parameters": params,
						},
						"views": [
							{
								parameters: params,
							}
						]
					},
				}
			};
			console.group();
			console.log("sending request");
			console.log(offerData);
			console.log(viewName);
			adobe.target.getOffers(offerData).then((response: any) => {
				console.log("Got response");
				console.log(response);
				adobe.target.applyOffers({ response: response });
			}).catch((error: any) => console.log("Error", error))
				.finally(adobe?.target?.triggerView(viewName, { page: isPageTransition }));
			console.groupEnd();
		}
	}

	const trackTargetEvent = (mboxName: string = ADOBE_TARGET_CONSTANTS.MBOX_NAMES.DEFAULT_MBOX, params: {}): void => {
		if (typeof adobe != 'object') {
			console.error('Adobe not loaded yet, cannot trigger view.');
		} else {
			try {
				adobe?.target?.trackEvent({
					mbox: mboxName,
					params: params
				});
			} catch (e) {
				console.log("Target purchase event failure");
				console.log(e);
			}
		}
	};

	/**
	 * Maps an existing tealium tracking event to the data that target is expecting.
	 * See https://experienceleague.adobe.com/docs/target-dev/developer/client-side/at-js-implementation/functions-overview/adobe-target-getoffers-atjs-2.html?lang=en for more details.
	 * @param tealiumData 
	 * @returns 
	 */
	const convertTealiumEventData = (tealiumData: any): {} => {
		const adobeEventData: any = {};

		if (tealiumData.page_type) { adobeEventData.PageType = tealiumData.page_type; }
		if (tealiumData.page_name) { adobeEventData.PageName = tealiumData.page_name; }
		if (tealiumData.hotline_user) { adobeEventData.UserAdvantage = tealiumData.hotline_user; }
		if (tealiumData.platinum_user) { adobeEventData.UserPlatinum = tealiumData.platinum_user; }
		if (tealiumData.greatPlains_users) { adobeEventData.UserGreatPlains = tealiumData.greatPlains_users; }
		if (tealiumData.store_number) { adobeEventData.StoreNumber = tealiumData.store_number; }
		if (tealiumData.cart_subtotal) { adobeEventData.orderTotal = tealiumData.cart_subtotal; }
		if (tealiumData.product_brand) { adobeEventData.ProdBrand = tealiumData.product_brand.join(', '); }
		if (tealiumData.product_price) { adobeEventData.ProdPrice = tealiumData.product_price.map(String).join(', '); }
		if (tealiumData.product_id) { adobeEventData.ProdId = tealiumData.product_id.join(', '); }
		if (tealiumData.product_sku) { adobeEventData.ProdSku = tealiumData.product_sku.join(', '); }
		if (tealiumData.product_category_1) { adobeEventData.ProdCat1 = tealiumData.product_category_1; }
		if (tealiumData.product_category_2) { adobeEventData.ProdCat2 = tealiumData.product_category_2; }
		if (tealiumData.product_category_3) { adobeEventData.ProdCat3 = tealiumData.product_category_3; }
		if (tealiumData.product_category_id_1) { adobeEventData.ProdCatId1 = tealiumData.product_category_id_1; }
		if (tealiumData.product_category_id_2) { adobeEventData.ProdCatId2 = tealiumData.product_category_id_2; }
		if (tealiumData.product_category_id_3) { adobeEventData.ProdCatId3 = tealiumData.product_category_id_3; }
		if (tealiumData.product_has_video) { adobeEventData.ProdHasVideo = tealiumData.product_has_video; }
		if (tealiumData.product_onsale) { adobeEventData.ProdOnSale = tealiumData.product_onsale; }
		if (tealiumData.product_free_ship) { adobeEventData.ProdFreeShip = tealiumData.product_free_ship; }
		if (tealiumData.product_hotline) { adobeEventData.ProdHotline = tealiumData.product_hotline; }
		if (tealiumData.product_truck_ship) { adobeEventData.ProdTruckShip = tealiumData.product_truck_ship; }
		if (tealiumData.product_store_available) { adobeEventData.ProdStoreAvailable = tealiumData.product_store_available; }
		if (tealiumData.product_store_only) { adobeEventData.ProdStoreOnly = tealiumData.product_store_only; }
		if (tealiumData.category_affinity) { adobeEventData.user.categoryId = tealiumData.category_affinity; }
		if (tealiumData.bopis_eligible) { adobeEventData.EligibleBOPIS = tealiumData.bopis_eligible; }
		if (tealiumData.advantage_shipping_offer) { adobeEventData.Advantage_off = tealiumData.advantage_shipping_offer; }
		if (tealiumData.truck_shipping_offer) { adobeEventData.TruckShipping = tealiumData.truck_shipping_offer; }
		if (tealiumData.order_store) { adobeEventData.StoreNumber = tealiumData.order_store; }
		if (tealiumData.order_id) { adobeEventData.orderId = tealiumData.order_id; }
		if (tealiumData.order_total) { adobeEventData.orderTotal = tealiumData.order_total; }

		return adobeEventData;
	}

	return { getTargetExperiance, updateTargetExperiances, triggerTargetView, triggerTargetViewWithParameters, trackTargetEvent, convertTealiumEventData }
};

export { useAdobeTarget };